import { useUser } from '@price-for-profit/auth';
import { Help } from '@mui/icons-material';
import { Card, CardContent, Grid } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { NumberInput, SelectInput, TextInput } from 'shared/components/forms/controls';
import {
    LABEL_COMPLEXITIES,
    LABEL_COMPLEXITIES_TOOLTIPS,
    LABEL_COMPLEXITY_OPTIONS,
    SIMILARITIES_TO_CUSTOMERS_EXISTING_LABELS,
    SIMILARITIES_TO_CUSTOMERS_EXISTING_LABELS_OPTIONS,
    SIMILARITIES_TO_CUSTOMERS_EXISTING_TOOLTIPS,
} from 'shared/constants';
import { useSelectFormProduct } from 'shared/hooks';
import {
    useCustomerBusinessUnitSearch,
    useCustomerEndMarketSearch,
    useDecoratingTechnologySearch,
    usePrintingTechnologySearch,
    useRecommendedPlantSearch,
} from 'shared/queries';
import { useSubstrateSearch } from 'shared/queries/substrate-search';
import {
    DivisionEnum,
    ERPEnum,
    ProductForm,
    TLabelComplexityKey,
    TProductFormWithId,
    TSimilarityToCustomersExistingLabelKey,
} from 'shared/types';
import { buildFieldNameWithIndex, getDivisionByPermission, getERPByPermission } from 'shared/utility';
import { ProductDropdown } from './dropdown';
import { BrandDropdown } from './brand-dropdown';
import { usePermissionERPDivisionState } from 'shared/contexts';

interface ProductCardProps {
    product: TProductFormWithId;
    index: number;
    defaultEndMarketProduct: string;
}

export function ProductCard({ product, index, defaultEndMarketProduct }: ProductCardProps) {
    const user = useUser();
    const { divisionEnabled, erpEnabled } = usePermissionERPDivisionState();

    const isRadius = getERPByPermission(erpEnabled) === ERPEnum.RADIUS;
    const isGlobeTek = getERPByPermission(erpEnabled) === ERPEnum.GLOBETEK;
    const isWandS = divisionEnabled.some(d => getDivisionByPermission(d) === DivisionEnum.WANDS);
    const isFandB = divisionEnabled.some(d => getDivisionByPermission(d) === DivisionEnum.FANDB);
    const { setValue, watch } = useFormContext();
    const buildFieldName = useCallback((i: number) => buildFieldNameWithIndex<ProductForm>(i), []);

    const recommendedPlantCode = watch(buildFieldName(index)('recommendedPlantCode'));
    const recommendedPlantName = watch(buildFieldName(index)('recommendedPlant'));
    const businessUnit = watch(buildFieldName(index)('businessUnit'));
    const endMarket = watch(buildFieldName(index)('endMarket'));

    const [onProductSelect, onProductClear] = useSelectFormProduct({ index });
    const {
        data: customerEndMarketData,
        isLoading: isEndMarketLoading,
        isFetching: isEndMarketFetching,
    } = useCustomerEndMarketSearch(businessUnit, user, isRadius, isFandB, isWandS);
    const {
        data: recommendedPlantData,
        isLoading: isRecommendedPlantLoading,
        isFetching: isRecommendedPlantFetching,
    } = useRecommendedPlantSearch(businessUnit, user, isRadius, isWandS);
    const {
        data: decoratingTechnologyData,
        isLoading: isDecoratingTechnologyLoading,
        isFetching: isDecoratingTechnologyFetching,
    } = useDecoratingTechnologySearch(businessUnit, user, isRadius, isFandB, isWandS);
    const {
        data: substrateData,
        isLoading: isSubstrateDataLoading,
        isFetching: isSubstrateDataFetching,
    } = useSubstrateSearch(businessUnit, user, isRadius, isFandB, isWandS);
    const {
        data: printingTechnologyData,
        isLoading: isPrintingTechnologyLoading,
        isFetching: isPrintingTechnologyFetching,
    } = usePrintingTechnologySearch(businessUnit, user, isRadius, isFandB, isWandS);
    const {
        data: customerBusinessUnitData,
        isLoading: isBusinessUnitLoading,
        isFetching: isBusinessUnitFetching,
    } = useCustomerBusinessUnitSearch(user, isRadius, isWandS);

    useEffect(() => {
        const recommendedPlant = recommendedPlantData.find(r => r.plantcode === recommendedPlantCode);
        if (recommendedPlant) {
            setValue(buildFieldName(index)('recommendedPlant'), recommendedPlant?.plantname || '');
        }
    }, [recommendedPlantCode, recommendedPlantData, setValue, buildFieldName, index]);

    useEffect(() => {
        if (defaultEndMarketProduct && !endMarket) {
            setValue(buildFieldName(index)('endMarket'), defaultEndMarketProduct);
        }
    }, [defaultEndMarketProduct, buildFieldName, setValue, index, endMarket]);

    return (
        <Grid xs={12} item sx={{ padding: '0' }}>
            <Card>
                <CardContent>
                    <Grid container spacing={1}>
                        <Grid xs={12} item>
                            {(product.isNew || watch(buildFieldName(index)('isNew'))) &&
                            watch(buildFieldName(index)('number')) ? (
                                <TextInput name={buildFieldName(index)('name')} label='Product Description' />
                            ) : (
                                <ProductDropdown
                                    name={buildFieldName(index)('name')}
                                    onSelect={product => onProductSelect(product, 'name')}
                                    onClear={onProductClear}
                                    businessUnit={businessUnit}
                                />
                            )}
                        </Grid>
                        <Grid xs={12} item>
                            {(product.isNew || watch(buildFieldName(index)('isNew'))) &&
                            watch(buildFieldName(index)('name')) ? (
                                <TextInput name={buildFieldName(index)('number')} label='Product Number' />
                            ) : (
                                <ProductDropdown
                                    name={buildFieldName(index)('number')}
                                    onSelect={product => onProductSelect(product, 'number')}
                                    onClear={onProductClear}
                                    businessUnit={businessUnit}
                                />
                            )}
                        </Grid>
                        <Grid xs={12} item>
                            <BrandDropdown name={buildFieldName(index)('brand')} businessUnit={businessUnit} />
                        </Grid>
                        <Grid container item spacing={1}>
                            <Grid xs={6} item>
                                <NumberInput
                                    name={buildFieldName(index)('estimatedAnnualQuantity')}
                                    label='Estimated Annual Quantity'
                                    rules={{
                                        min: {
                                            message: 'Estimated Annual Quantity must be greater than 0',
                                            value: 0.001,
                                        },
                                    }}
                                    decimalPlaces={0}
                                />
                            </Grid>
                            <Grid xs={6} item>
                                <NumberInput
                                    name={buildFieldName(index)('estimatedCostPerUnit')}
                                    label='Estimated Cost Per Unit'
                                    prefix='$'
                                    rules={{
                                        min: {
                                            message: 'Estimated Cost Per Unit must be greater than 0',
                                            value: 0.0001,
                                        },
                                    }}
                                    decimalPlaces={4}
                                />
                            </Grid>
                        </Grid>

                        <Grid container item spacing={1}>
                            <Grid xs={6} item>
                                <SelectInput
                                    name={buildFieldName(index)('similarityToCustomersExistingLabel')}
                                    label="Similarity To Customer's Existing Label"
                                    options={SIMILARITIES_TO_CUSTOMERS_EXISTING_LABELS_OPTIONS}
                                    getOptionLabel={(option: TSimilarityToCustomersExistingLabelKey) =>
                                        SIMILARITIES_TO_CUSTOMERS_EXISTING_LABELS[option] || ''
                                    }
                                    getOptionSelected={(
                                        option: TSimilarityToCustomersExistingLabelKey,
                                        value: TSimilarityToCustomersExistingLabelKey
                                    ) => option === value}
                                    optionIcon={<Help />}
                                    optionKey={(option: TSimilarityToCustomersExistingLabelKey) => option}
                                    optionLabel={(option: TSimilarityToCustomersExistingLabelKey) =>
                                        SIMILARITIES_TO_CUSTOMERS_EXISTING_LABELS[option]
                                    }
                                    optionTooltip={(option: TSimilarityToCustomersExistingLabelKey) =>
                                        SIMILARITIES_TO_CUSTOMERS_EXISTING_TOOLTIPS[option]
                                    }
                                />
                            </Grid>
                            <Grid xs={6} item>
                                <SelectInput
                                    name={buildFieldName(index)('labelComplexity')}
                                    label='Label Complexity'
                                    options={LABEL_COMPLEXITY_OPTIONS}
                                    getOptionLabel={(option: TLabelComplexityKey) => LABEL_COMPLEXITIES[option] || ''}
                                    getOptionSelected={(option: TLabelComplexityKey, value: TLabelComplexityKey) =>
                                        option === value
                                    }
                                    optionIcon={<Help />}
                                    optionKey={(option: TLabelComplexityKey) => option}
                                    optionLabel={(option: TLabelComplexityKey) => LABEL_COMPLEXITIES[option]}
                                    optionTooltip={(option: TLabelComplexityKey) => LABEL_COMPLEXITIES_TOOLTIPS[option]}
                                />
                            </Grid>
                        </Grid>

                        <Grid xs={12} item>
                            <SelectInput
                                name={buildFieldName(index)('endMarket')}
                                label='End Market'
                                options={customerEndMarketData?.sort((a, b) => a.localeCompare(b)) || []}
                                loading={isEndMarketLoading || isEndMarketFetching}
                                getOptionLabel={option => (option || '').toUpperCase()}
                                getOptionSelected={(option, value) => option === value}
                                rules={{
                                    required: {
                                        message: 'Please select an End Market to continue',
                                        value: true,
                                    },
                                }}
                            />
                        </Grid>

                        <Grid xs={12} item>
                            <SelectInput
                                name={buildFieldName(index)('recommendedPlant')}
                                label='Recommended Plant'
                                options={recommendedPlantData?.map(r => r.plantname) || []}
                                loading={isRecommendedPlantLoading || isRecommendedPlantFetching}
                                getOptionLabel={option => (option || '').toUpperCase()}
                                getOptionSelected={(option, value) => option === value}
                                onSelect={option => {
                                    const selectedPlant = recommendedPlantData.find(r => r.plantname === option);
                                    const code = selectedPlant?.plantcode;
                                    const division = selectedPlant?.division.toUpperCase();
                                    setValue(buildFieldName(index)('recommendedPlantCode'), code);
                                    setValue(buildFieldName(index)('businessUnit'), division);
                                    if (selectedPlant?.plantname.toUpperCase() === 'ROCHESTER') {
                                        setValue(buildFieldName(index)('substrate'), 'DO NOT USE FOR ROCHESTER');
                                        setValue(buildFieldName(index)('decoratingTechnology'), '');
                                    } else {
                                        setValue(buildFieldName(index)('substrate'), '');
                                        if (isGlobeTek) {
                                            setValue(
                                                buildFieldName(index)('decoratingTechnology'),
                                                'DO NOT USE - ROCHESTER ONLY'
                                            );
                                        }
                                    }
                                }}
                                rules={{
                                    required: {
                                        message: 'Please select a Recommended Plant to continue',
                                        value: true,
                                    },
                                }}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <SelectInput
                                name={buildFieldName(index)('businessUnit')}
                                label='Business Unit'
                                loading={isBusinessUnitLoading || isBusinessUnitFetching}
                                options={customerBusinessUnitData.sort((a, b) => a.localeCompare(b)) || []}
                                getOptionLabel={option => option.toUpperCase()}
                                getOptionSelected={(option, value) => option === value}
                                rules={{
                                    required: {
                                        message: 'Please select a Business Unit to continue',
                                        value: true,
                                    },
                                }}
                            />
                        </Grid>
                        {isGlobeTek && (
                            <Grid xs={12} item>
                                <SelectInput
                                    name={buildFieldName(index)('substrate')}
                                    label='Substrate'
                                    options={substrateData?.sort((a, b) => a.localeCompare(b)) || []}
                                    loading={isSubstrateDataFetching || isSubstrateDataLoading}
                                    getOptionLabel={option => (option || '').toUpperCase()}
                                    getOptionSelected={(option, value) => option === value}
                                    disabled={watch(buildFieldName(index)('substrate')) === 'DO NOT USE FOR ROCHESTER'}
                                    rules={{
                                        required: {
                                            message: 'Please select a Substrate to continue',
                                            value: true,
                                        },
                                    }}
                                />
                            </Grid>
                        )}

                        <Grid xs={12} item>
                            <SelectInput
                                name={buildFieldName(index)('decoratingTechnology')}
                                label='Decorating Technology'
                                options={decoratingTechnologyData?.sort((a, b) => a.localeCompare(b)) || []}
                                loading={isDecoratingTechnologyLoading || isDecoratingTechnologyFetching}
                                getOptionLabel={option => (option || '').toUpperCase()}
                                getOptionSelected={(option, value) => option === value}
                                disabled={isGlobeTek && (recommendedPlantName || '').toUpperCase() !== 'ROCHESTER'}
                                rules={{
                                    required: {
                                        message: 'Please select a Decorating Technology to continue',
                                        value: true,
                                    },
                                }}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <SelectInput
                                name={buildFieldName(index)('printingTechnology')}
                                label='Printing Technology'
                                options={printingTechnologyData?.sort((a, b) => a.localeCompare(b)) || []}
                                loading={isPrintingTechnologyLoading || isPrintingTechnologyFetching}
                                getOptionLabel={option => (option || '').toUpperCase()}
                                getOptionSelected={(option, value) => option === value}
                                rules={{
                                    required: {
                                        message: 'Please select a Printing Technology to continue',
                                        value: true,
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    );
}
