import { useI2pServerDataGrid } from '@price-for-profit/data-grid';
import { useService } from '@price-for-profit/service-provider';
import { useUser } from '@price-for-profit/auth';
import { useNavigate } from 'react-router-dom';
import { QUERY_KEYS } from 'shared/constants';
import { usePermissionERPDivisionState, useQuoteDeleteDialogState } from 'shared/contexts';
import { IQuote } from 'shared/types';
import { getQuotesColumns } from './columns';
import { QueryKey } from '@tanstack/react-query';
import { Quote } from './quote';
import { useTrackPageEvent } from '@price-for-profit/user-activity-tracking';
import { useTrackButtonClick } from 'shared/hooks';

export function QuoteResolver() {
    // Check for additional permissions or any logic before rendering a page
    const { quoteService, appConfig } = useService();
    const navigate = useNavigate();
    const user = useUser();
    const { divisionEnabled } = usePermissionERPDivisionState();
    const { trackButtonClick } = useTrackButtonClick();

    useTrackPageEvent({
        action: 'QuoteListPage',
        clientId: appConfig.clientId,
        user,
    });

    const navigateToEditPage = async (quoteId: string) => {
        await trackButtonClick({ action: 'Edit Quote' });

        navigate(`/quote/edit/${quoteId}`);
    };

    const { toggleDeleteQuoteDialog, setQuoteToDelete, setQuotesQueryKey } = useQuoteDeleteDialogState();

    const handleDeleteClicked = async (quote: IQuote, queryKey: QueryKey) => {
        await trackButtonClick({ action: 'Delete Quote' });

        setQuotesQueryKey(queryKey);
        setQuoteToDelete(quote);
        toggleDeleteQuoteDialog();
    };

    const navigateToNewQuoteCopyPage = (quote: IQuote) => {
        navigate(`/quote/new`, {
            state: {
                quoteToCopy: quote,
            },
        });
    };

    const columns = getQuotesColumns({
        navigateToEditPage,
        navigateToNewQuoteCopyPage,
        isPricingLeadership: user?.isPricingLeadership || divisionEnabled.length > 1,
        handleDeleteClicked,
    });

    const { getDataGridProps } = useI2pServerDataGrid<IQuote>({
        columns,
        name: QUERY_KEYS.quote,
        getData: async state => {
            return quoteService.getGridData(state, columns, user?.isPricingLeadership || divisionEnabled.length > 1);
        },
        initialState: {
            sortModel: [{ field: 'date', sort: 'desc' }],
        },
    });

    const dataGridProps = getDataGridProps();

    return <Quote dataGridProps={dataGridProps} />;
}
