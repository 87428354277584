import { Delete } from '@mui/icons-material';
import { useGridApiContext, GridActionsCellItem } from '@mui/x-data-grid-premium';
import { QueryKey } from '@tanstack/react-query';
import { QUERY_KEYS } from 'shared/constants';
import { IQuote } from 'shared/types';

interface DeleteQuoteActionButtonProps {
    quote: IQuote;
    handleDeleteClicked: (quote: IQuote, queryKey: QueryKey) => void;
}

export function DeleteQuoteActionButton({ quote, handleDeleteClicked }: DeleteQuoteActionButtonProps) {
    const { current } = useGridApiContext();
    const queryKey: QueryKey = [
        QUERY_KEYS.quote,
        {
            filterModel: current.state.filter.filterModel,
            pageNumber: current.state.pagination.paginationModel.page,
            pageSize: current.state.pagination.paginationModel.pageSize,
            sortModel: current.state.sorting.sortModel,
        },
    ];

    return (
        <GridActionsCellItem
            icon={<Delete titleAccess='Delete' />}
            label='Delete'
            color='primary'
            onClick={() => handleDeleteClicked(quote, queryKey)}
        />
    );
}
