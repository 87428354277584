import { useUser } from '@price-for-profit/auth';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { GridSelect } from 'shared/components/datagrid/grid-select';
import { usePermissionERPDivisionState } from 'shared/contexts';
import { useCustomerEndMarketSearch } from 'shared/queries';
import { DivisionEnum, ERPEnum, Product, ProductForm } from 'shared/types';
import { getDivisionByPermission, getERPByPermission } from 'shared/utility';

export function EndMarketGridSelect(params: GridRenderCellParams<ProductForm, string>) {
    const user = useUser();
    const { divisionEnabled, erpEnabled } = usePermissionERPDivisionState();

    const isRadius = getERPByPermission(erpEnabled) === ERPEnum.RADIUS;
    const isFandB = divisionEnabled.some(d => getDivisionByPermission(d) === DivisionEnum.FANDB);
    const isWandS = divisionEnabled.some(d => getDivisionByPermission(d) === DivisionEnum.WANDS);

    const businessUnit = (params.row as Product).businessUnit;
    const { data, isLoading, isFetching } = useCustomerEndMarketSearch(businessUnit, user, isRadius, isFandB, isWandS);

    return (
        <GridSelect
            id={'endMarketGridSelect'}
            data={data}
            isLoading={isLoading}
            isFetching={isFetching}
            params={params}
        />
    );
}
