import { GridRenderCellParams, useGridApiContext } from '@mui/x-data-grid-premium';
import { NumberInput } from 'shared/components/forms/controls';
import { DivisionEnum, ERPEnum, ProductForm, ReviewedEnum } from 'shared/types';

interface IRevisedPriceInputGridProps {
    params: GridRenderCellParams<ProductForm, string>;
    erp: string;
    division: string;
    updateIsReviewedOnFinalMarginChange: (index: number, value: ReviewedEnum) => void;
    updateFinalMarginOnRevisedPriceChange: (index: number, finalMargin: string, revisedPrice: string) => void;
}

export function RevisedPriceInputGrid({
    params,
    erp,
    division,
    updateIsReviewedOnFinalMarginChange,
    updateFinalMarginOnRevisedPriceChange,
}: IRevisedPriceInputGridProps) {
    const apiRef = useGridApiContext();

    const handleValueChange = (newValue: any) => {
        apiRef.current.setEditCellValue({ id: params.id, field: params.field, value: newValue });
        let finalMargin = ((newValue - Number(params.row.estimatedCostPerUnit)) / newValue).toFixed(4);
        if (erp === ERPEnum.RADIUS && division === DivisionEnum.WANDS) {
            finalMargin = (
                (newValue - Number(params.row.estimatedCostPerUnit)) /
                Number(params.row.estimatedCostPerUnit)
            ).toFixed(4);
        }
        updateIsReviewedOnFinalMarginChange(
            apiRef.current.getRowIndexRelativeToVisibleRows(params.id),
            params.row.targetMargin === finalMargin ? ReviewedEnum.NO : ReviewedEnum.YES
        );
        updateFinalMarginOnRevisedPriceChange(
            apiRef.current.getRowIndexRelativeToVisibleRows(params.id),
            finalMargin.toString(),
            newValue
        );
    };

    return (
        <NumberInput
            label=''
            prefix='$'
            name={`revisedPrice` + params.id}
            defaultValue={params.value}
            onChangeValue={handleValueChange}
            decimalPlaces={4}
        />
    );
}
