import { useService } from '@price-for-profit/service-provider';
import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from 'shared/constants';

export function useCustomerBusinessUnitSearch(user: drive.UserInfo | null, isRadius: boolean, isWandS: boolean) {
    const { quoteService } = useService();
    const initialData: string[] = [];
    const { data, isFetching, isLoading } = useQuery<string[], Error>({
        queryKey: [QUERY_KEYS.businessUnit],
        queryFn: () =>
            quoteService.getCustomerBusinessUnits(user, isRadius, isWandS).then(data => data.map(d => d.division)),
        initialData,
    });

    return { data, isFetching, isLoading } as const;
}
