import { useQuery } from '@tanstack/react-query';
import { IProductMarginScatter } from 'shared/types';
import { QUERY_KEYS } from 'shared/constants';
import { useService } from '@price-for-profit/service-provider';

export function useProductScatterData(peerGroup: string) {
    const { productService } = useService();
    const initialData: IProductMarginScatter[] = [];
    const { data, isFetching, isLoading } = useQuery<IProductMarginScatter[], Error>({
        queryKey: [QUERY_KEYS.productScatter, peerGroup],
        queryFn: () => productService.getProductScatterData(peerGroup),
        initialData,
    });

    return { data, isFetching, isLoading } as const;
}
