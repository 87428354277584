import { getAppPermissions, useUser } from '@price-for-profit/auth';
import { Box, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { SwitchGroup } from 'shared/components/forms/controls';
import { ERP_OPTIONS } from 'shared/constants';
import { CLIENT_ID, IProfileConfiguration } from 'shared/types';
import { getERPByPermission } from 'shared/utility';

export function ERPGroupSwitch() {
    const user = useUser();
    const permissions = getAppPermissions(user?.insightClaims || '', CLIENT_ID);
    const { watch, setValue } = useFormContext<IProfileConfiguration>();

    const swapERP = useCallback(
        (key: string) => {
            setValue('erpEnabled', key);
            setValue('divisionEnabled', []);
        },
        [setValue]
    );

    return (
        <Box display={'flex'} pt={2} pb={1} flexDirection={'column'}>
            <Typography variant='subtitle1' sx={{ fontWeight: 'bold' }}>
                ERP
            </Typography>
            {ERP_OPTIONS.filter(d => permissions.includes(d.key as `mcc.${string}`)).length > 1 ? (
                <Box pt={1}>
                    <SwitchGroup
                        options={ERP_OPTIONS.filter(d => permissions.includes(d.key as `mcc.${string}`))}
                        checkedValue={watch('erpEnabled')}
                        handleChange={swapERP}
                    />
                </Box>
            ) : (
                <Typography variant='subtitle1'>
                    You have access to <b>{getERPByPermission(watch('erpEnabled'))}</b>
                </Typography>
            )}
        </Box>
    );
}
