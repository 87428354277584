import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routing/routes';
import { QUERY_KEYS } from 'shared/constants';
import { IQuote } from 'shared/types';
import { useService } from '@price-for-profit/service-provider';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';

export function useQuote(id: string) {
    const { quoteService } = useService();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { data, isLoading, isFetching, isError } = useQuery<IQuote, Error>({
        queryKey: [QUERY_KEYS.singleQuote, id],
        queryFn: () => quoteService.getQuote(id),
        retry: false,
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        if (isError) {
            enqueueSnackbar({
                message: `Quote ${id} does not exist..`,
                variant: 'error',
            });
            navigate(ROUTES.quote);
        }
    }, [id, isError, navigate, enqueueSnackbar]);

    return {
        data,
        isLoading,
        isFetching,
    } as const;
}
