import { useService } from '@price-for-profit/service-provider';
import { useUser } from '@price-for-profit/auth';
import { useTrackPageEvent } from '@price-for-profit/user-activity-tracking';
import { useParams } from 'react-router-dom';
import { useQuote } from 'shared/queries';
import { EditQuote } from './edit';

export function EditQuoteResolver() {
    const { id } = useParams<{ id: string }>();
    const { data, isFetching } = useQuote(id || '');
    const { appConfig } = useService();
    const user = useUser();

    useTrackPageEvent({
        action: 'QuoteEditPage',
        clientId: appConfig.clientId,
        user,
    });

    if (!data || isFetching) {
        return null;
    }
    return <EditQuote quote={data} />;
}
