import { useQuery } from '@tanstack/react-query';
import { IRecommendedPlant } from 'shared/types';
import { QUERY_KEYS } from 'shared/constants';
import { useService } from '@price-for-profit/service-provider';

export function useRecommendedPlantSearch(
    businessUnit: string,
    user: drive.UserInfo | null,
    isRadius: boolean,
    isWandS: boolean
) {
    const { productService } = useService();
    const initialData: IRecommendedPlant[] = [];
    const { data, isFetching, isLoading } = useQuery<IRecommendedPlant[], Error>({
        queryKey: [QUERY_KEYS.recommendedPlant, businessUnit],
        queryFn: () => productService.getRecommendedPlants(businessUnit, user, isRadius, isWandS),
        initialData,
    });

    return { data, isFetching, isLoading } as const;
}
