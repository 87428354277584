import { Stack, Typography, IconButton, Icon } from '@mui/material';
import { Box } from '@mui/system';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { useCopyToClipboard } from 'shared/hooks/use-copy-to-clipboard';
import { ProductForm } from 'shared/types';

export function ProductIdGridDisplay(params: GridRenderCellParams<ProductForm, string>) {
    const { isCopied, copy } = useCopyToClipboard(params.value || '');
    return (
        <Stack direction='row' spacing={1} alignItems='center'>
            {params.value && (
                <Box>
                    <Stack spacing={0.5} direction='row' alignItems='center'>
                        <IconButton onClick={copy} title='Copy to Clipboard'>
                            <Icon>{isCopied ? 'check_circle' : 'content_copy'}</Icon>
                        </IconButton>
                        {isCopied && <span>Copied!</span>}
                    </Stack>
                </Box>
            )}
            <Typography>{params.value || '-'}</Typography>
        </Stack>
    );
}
