import { useService } from '@price-for-profit/service-provider';
import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from 'shared/constants';

export function usePrintingTechnologySearch(
    businessUnit: string,
    user: drive.UserInfo | null,
    isRadius: boolean,
    isFandB: boolean,
    isWandS: boolean
) {
    const { productService } = useService();
    const initialData: string[] = [];
    const { data, isFetching, isLoading } = useQuery<string[], Error>({
        queryKey: [QUERY_KEYS.printingTechnology, businessUnit],
        queryFn: () =>
            productService
                .getPrintingTechnologies(businessUnit, user, isRadius, isFandB, isWandS)
                .then(data => data.map(r => r.displayname)),
        initialData,
    });

    return { data, isFetching, isLoading } as const;
}
