import { useQuery } from '@tanstack/react-query';
import { IProductLookup } from 'shared/types';
import { QUERY_KEYS } from 'shared/constants';
import { useService } from '@price-for-profit/service-provider';

export function useProductSearch(
    businessUnit: string,
    user: drive.UserInfo | null,
    query: string,
    isRadius: boolean,
    isFandB: boolean,
    isWandS: boolean
) {
    const { productService } = useService();
    const initialData: IProductLookup[] = [];
    const { data, isFetching, isLoading } = useQuery<IProductLookup[], Error>({
        queryKey: [QUERY_KEYS.product, query, businessUnit],
        queryFn: () => productService.getProductsData(businessUnit, user, query, isRadius, isFandB, isWandS),
        initialData,
        enabled: Boolean(query),
    });

    return { data, isFetching, isLoading } as const;
}
