import { useService } from '@price-for-profit/service-provider';
import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from 'shared/constants';
import { IBrand } from 'shared/types';

export function useBrandSearch(
    query: string,
    businessUnit: string,
    user: drive.UserInfo | null,
    isRadius: boolean,
    isFandB: boolean,
    isWandS: boolean
) {
    const { productService } = useService();
    const initialData: IBrand[] = [];
    const { data, isFetching, isLoading } = useQuery<IBrand[], Error>({
        queryKey: [QUERY_KEYS.brands, query, businessUnit],
        queryFn: () => productService.getBrands(businessUnit, user, isRadius, isFandB, isWandS),
        initialData,
    });

    return { data, isFetching, isLoading } as const;
}
