import { useService } from '@price-for-profit/service-provider';
import { CalculationPayload, CalculationResponse } from '@price-for-profit/micro-services';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

export function useExecuteModel<I, O>(modelId: string) {
    const { quoteService } = useService();
    const { enqueueSnackbar } = useSnackbar();
    const mutation = useMutation<CalculationResponse<O>, Error, CalculationPayload<I>>({
        mutationFn: request => quoteService.calculate<I, O>({ modelId, payload: request }),
        onSuccess: () => {
            enqueueSnackbar(`Data updated successfully`, { variant: 'success' });
        },
        onError: () => {
            enqueueSnackbar(`There was a problem getting data from model: ${modelId}`, { variant: 'error' });
        },
    });

    return mutation;
}
