import { useService } from '@price-for-profit/service-provider';
import { useMutation } from '@tanstack/react-query';
import { ISendApprovalNotificationProps } from 'shared/types';

export function useSendApprovalQuoteNotification() {
    const { quoteService } = useService();

    const mutation = useMutation<void, Error, ISendApprovalNotificationProps>({
        mutationFn: request => quoteService.sendRequestApprovalQuoteNotification(request),
    });

    return mutation;
}
