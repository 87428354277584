import { NavigateBefore, Task, Analytics } from '@mui/icons-material';

import { Box, Button, Grid, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import { IQuote, IQuoteExcelExport, ProductForm, QuoteStatusEnum } from 'shared/types';
import { CustomerFields } from '../shared/customer/customer-fields';
import { DealInfoFields } from '../shared/deal-info/deal-info-fields';
import { DealLevelSummary } from '../shared/deal-level/summary';
import { ProductFields } from '../shared/product/fields';
import { useFormContext } from 'react-hook-form';
import { getExcelExportData } from 'shared/utility';
import { useExcelExport } from 'shared/mutations';
import { EXPORT_QUOTE_ID } from 'shared/constants';
import { MarginVariance } from './marginVariance';
import { ExportQuoteButton } from '../shared/export-quote-button';
import { SubmitNotesFields } from '../shared/notes';
import { SaveQuoteButtons } from '../shared/save-quote-buttons';
import { QUOTE_FORM_STYLES } from '../styles';
import { useTrackQuoteSubmitEvent } from '@price-for-profit/user-activity-tracking';
import { useService } from '@price-for-profit/service-provider';
import { useTrackButtonClick } from 'shared/hooks';

interface INewQuoteFormProps {
    user: drive.UserInfo | null;
    cancel: () => void;
}

export function NewQuoteForm({ user, cancel }: INewQuoteFormProps) {
    const { appConfig } = useService();
    const { watch, setValue, getValues } = useFormContext<IQuote>();
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const excelMutation = useExcelExport<IQuoteExcelExport>();

    const trackQuoteSubmitEvent = useTrackQuoteSubmitEvent(appConfig.clientId);
    const { trackButtonClick } = useTrackButtonClick();

    const quoteId = watch('id') as string;
    const watchProducts = watch('products') as ProductForm[];
    const watchQuoteNumber = watch('quoteNumber') as number;
    const showCompleteButton = !watchProducts.some(p => p.finalMargin && Number(p.finalMargin) < 0.25);

    const saveQuote = useCallback(async () => {
        setValue('revisedBy', (user?.displayName || '').toUpperCase());
        setValue('revisedOn', new Date().toISOString());

        await trackButtonClick({ action: 'Save Quote' });
    }, [setValue, user, trackButtonClick]);

    const submitQuote = useCallback(async () => {
        setValue('status', QuoteStatusEnum.SUBMITTED);
        saveQuote();

        try {
            if (user) {
                await trackQuoteSubmitEvent.mutateAsync({
                    payload: {
                        quoteId,
                    },
                    correlationId: quoteId,
                    user,
                });
            }
        } catch (e) {
            console.error(e);
        }
    }, [quoteId, user, setValue, saveQuote, trackQuoteSubmitEvent]);

    const completeQuote = useCallback(() => {
        setValue('status', QuoteStatusEnum.COMPLETED);
        saveQuote();
    }, [setValue, saveQuote]);

    const saveQuoteAsDraft = useCallback(() => {
        setValue('status', QuoteStatusEnum.IN_PROCESS);
        saveQuote();
    }, [setValue, saveQuote]);

    const exportQuote = useCallback(async () => {
        if (!quoteId) {
            return;
        }
        try {
            const quote = getValues() as IQuote;
            const data: IQuoteExcelExport = getExcelExportData(quote);
            await excelMutation.mutateAsync({
                exportId: EXPORT_QUOTE_ID,
                data,
                fileName: `${data.customerName} - ${watchQuoteNumber}`,
            });

            await trackButtonClick({ action: 'Export Quote' });
        } catch (e) {
            console.error(e);
        }
    }, [quoteId, watchQuoteNumber, getValues, excelMutation, trackButtonClick]);

    const openMarginVariance = useCallback(async () => {
        await trackButtonClick({ action: 'Open Margin Variance' });

        setDialogOpen(true);
    }, [trackButtonClick]);

    return (
        <>
            <Grid container justifyContent='space-between' gap={3} sx={QUOTE_FORM_STYLES.formTopWrapper}>
                <Grid item container justifyContent={'space-between'}>
                    <Grid item flex={'1'}>
                        <Button startIcon={<NavigateBefore />} onClick={cancel} variant='outlined'>
                            Go back
                        </Button>
                    </Grid>
                    <Grid item container justifyContent='flex-end' gap={3} flex={'3'}>
                        <ExportQuoteButton isDisabled={!quoteId} onClick={exportQuote} />

                        <SaveQuoteButtons
                            showSaveButton
                            onSaveButtonClick={saveQuoteAsDraft}
                            showSubmitButton={!showCompleteButton}
                            onSubmitButtonClick={submitQuote}
                        />
                        {showCompleteButton && (
                            <Grid item>
                                <Button startIcon={<Task />} type='submit' variant='contained' onClick={completeQuote}>
                                    Complete Quote
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            <Box sx={QUOTE_FORM_STYLES.formWrapper}>
                <Box display='flex' justifyContent={'space-between'}>
                    <Box width='33%'>
                        <Typography variant='h5' sx={QUOTE_FORM_STYLES.formLeftTitle}>
                            Quote ID: {watchQuoteNumber || 'N/A'}
                            <Button variant='text' onClick={openMarginVariance}>
                                <Analytics />
                            </Button>
                        </Typography>
                    </Box>
                    <Box width='33%'>
                        <Typography variant='h5' sx={QUOTE_FORM_STYLES.formCenterTitle}>
                            New Quote
                        </Typography>
                    </Box>
                    <Box width='33%'></Box>
                </Box>
                <Box sx={QUOTE_FORM_STYLES.formContent}>
                    <Box>
                        <DealLevelSummary />
                    </Box>
                    <Box>
                        <Grid container spacing={1}>
                            <Grid xs={12} md={6} item>
                                <CustomerFields />
                            </Grid>
                            <Grid xs={12} md={6} item>
                                <DealInfoFields />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box>
                        <ProductFields />
                    </Box>
                    <Box>
                        <SubmitNotesFields />
                    </Box>
                    <Box sx={QUOTE_FORM_STYLES.formBottomActions}>
                        <Grid item container justifyContent='flex-end' gap={3}>
                            <Grid item flex={'1'} display={'flex'}>
                                <Button startIcon={<NavigateBefore />} onClick={cancel} variant='outlined'>
                                    Go back
                                </Button>
                            </Grid>

                            <SaveQuoteButtons
                                showSaveButton
                                onSaveButtonClick={saveQuoteAsDraft}
                                showSubmitButton={!showCompleteButton}
                                onSubmitButtonClick={submitQuote}
                            />
                            {showCompleteButton && (
                                <Grid item>
                                    <Button
                                        startIcon={<Task />}
                                        type='submit'
                                        variant='contained'
                                        onClick={completeQuote}
                                    >
                                        Complete Quote
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                    <MarginVariance dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} />
                </Box>
            </Box>
        </>
    );
}
