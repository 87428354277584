import { useUser } from '@price-for-profit/auth';
import { useDebounce } from '@price-for-profit/react-hooks';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { useCallback, useEffect, useMemo } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { usePermissionERPDivisionState } from 'shared/contexts';
import { useKeyCompetitorSearch } from 'shared/queries';
import { DivisionEnum, ERPEnum, IKeyCompetitorLookup, TCompetitivenessKey } from 'shared/types';
import { getDivisionByPermission, getERPByPermission } from 'shared/utility';

interface ISalesRepNameProps {
    name: `${string}` | `${string}.${string}` | `${string}.${number}`;
}

export function KeyCompetitorDropdown({ name = 'keyCompetitor' }: ISalesRepNameProps) {
    const user = useUser();
    const { divisionEnabled, erpEnabled } = usePermissionERPDivisionState();

    const isRadius = getERPByPermission(erpEnabled) === ERPEnum.RADIUS;
    const isWandS = divisionEnabled.some(d => getDivisionByPermission(d) === DivisionEnum.WANDS);
    const isFandB = divisionEnabled.some(d => getDivisionByPermission(d) === DivisionEnum.FANDB);

    const { watch, setValue, clearErrors } = useFormContext();
    const competitiveness = watch('competitiveness') as TCompetitivenessKey;
    const isKeyCompetitorRequired = useMemo<boolean>(() => competitiveness === 'HIGH', [competitiveness]);
    const {
        field: { ref, value, onChange },
        fieldState: { error },
    } = useController({
        name,
        rules: {
            required: {
                message: 'Please select a Key Competitor to continue',
                value: isKeyCompetitorRequired,
            },
        },
    });

    const search = (watch(name) as unknown) as string;
    const debouncedSearch = useDebounce(search, 300);
    const { data, isLoading, isFetching } = useKeyCompetitorSearch(debouncedSearch, user, isRadius, isFandB, isWandS);
    const loading = isLoading || isFetching;

    const onSelect = useCallback(
        (competitor: string | IKeyCompetitorLookup | null) => {
            if (typeof competitor === 'string') {
                setValue('keyCompetitor', '');
            } else if (competitor && competitor.inputValue) {
            } else {
                setValue('keyCompetitor', competitor?.displayname || '');
            }
        },
        [setValue]
    );

    const onClear = useCallback(() => {
        setValue('keyCompetitor', '');
    }, [setValue]);

    useEffect(() => {
        if (!competitiveness) {
            clearErrors(name);
        }
    }, [clearErrors, competitiveness, name]);

    return (
        <Autocomplete
            ref={ref}
            value={value}
            id={name}
            onChange={async (_, value, reason) => {
                onSelect(value);
                if (reason === 'clear') {
                    onClear();
                }
            }}
            onInputChange={(_, value, reason) => {
                if (reason === 'clear') {
                    onClear();
                }
                onChange(value);
            }}
            options={data.sort((a, b) => a.displayname.localeCompare(b.displayname)) || []}
            selectOnFocus
            freeSolo
            isOptionEqualToValue={(option, value) =>
                option.displayname === value.displayname || option.displayname === value.inputValue
            }
            getOptionLabel={option => (option.displayname || option.inputValue || option).toUpperCase()}
            renderOption={(props, option) => (
                <li {...props} key={option.uniqueid}>
                    {option.displayname.toUpperCase()}
                </li>
            )}
            data-testid={`${name}-combobox`}
            renderInput={params => (
                <TextField
                    variant='outlined'
                    {...params}
                    label={`Key Competitor (${isKeyCompetitorRequired ? 'Required' : 'Optional'})`}
                    error={Boolean(error)}
                    helperText={error?.message}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: loading ? (
                            <CircularProgress color='inherit' size={20} />
                        ) : (
                            params.InputProps.endAdornment
                        ),
                    }}
                    InputLabelProps={{ shrink: true }}
                />
            )}
        />
    );
}
