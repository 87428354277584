import { Box, LinearProgress } from '@mui/material';
import { DataGridPremium, GridPremiumSlotsComponent } from '@mui/x-data-grid-premium';
import { useTitle } from '@price-for-profit/react-hooks';
import { PickedSimpleDataGridPremiumProps } from '@price-for-profit/data-grid';
import { useQuoteDeleteDialogState } from 'shared/contexts';
import { useUpdateQuote } from 'shared/mutations';
import { QuoteStatusEnum } from 'shared/types';
import { QuoteDeleteConfirmDialog } from './quote-delete-confirm-dialog';
import { QuoteTableToolbar } from './table-toolbar';

interface IQuoteProps {
    dataGridProps: PickedSimpleDataGridPremiumProps;
}

export function Quote({ dataGridProps }: IQuoteProps) {
    useTitle(`MCC - Quote List`);
    const { quoteToDelete, quotesQueryKey } = useQuoteDeleteDialogState();
    const updateMutation = useUpdateQuote(quotesQueryKey);

    const handleQuoteDelete = () => updateMutation.mutate({ ...quoteToDelete!, status: QuoteStatusEnum.DELETED });
    return (
        <>
            <Box
                height='calc(100vh - 115px)'
                sx={{
                    '.MuiDataGrid-columnHeaderTitle': {
                        fontWeight: 'bold',
                    },
                    '& .drive-app-wps--cell--editable': {
                        bgcolor: '#dbe7f2',
                    },
                }}
            >
                <DataGridPremium
                    {...dataGridProps}
                    checkboxSelection
                    disableRowSelectionOnClick
                    slots={{
                        toolbar: QuoteTableToolbar as GridPremiumSlotsComponent['toolbar'],
                        loadingOverlay: LinearProgress as GridPremiumSlotsComponent['loadingOverlay'],
                    }}
                    disableVirtualization={false}
                    headerFilterHeight={80}
                    sx={{
                        '& .MuiDataGrid-columnHeaderTitle': {
                            textOverflow: 'clip',
                            whiteSpace: 'break-spaces',
                            lineHeight: 1,
                        },
                    }}
                />
            </Box>
            <QuoteDeleteConfirmDialog onConfirm={handleQuoteDelete} />
        </>
    );
}
