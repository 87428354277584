import { Add } from '@mui/icons-material';
import { Box, Button, Stack } from '@mui/material';
import { GridToolbarColumnsButton, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid-premium';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routing';
import { usePermissionERPDivisionState } from 'shared/contexts';
import { useTrackGenericEvent } from '@price-for-profit/user-activity-tracking';
import { useService } from '@price-for-profit/service-provider';
import { useUser } from '@price-for-profit/auth';

export function QuoteTableToolbar() {
    const navigate = useNavigate();
    const { divisionEnabled } = usePermissionERPDivisionState();
    const user = useUser();
    const { appConfig } = useService();
    const newQuoteClickMutation = useTrackGenericEvent(appConfig.clientId);

    const navigateToNewQuote = useCallback(async () => {
        try {
            if (user) {
                await newQuoteClickMutation.mutateAsync({
                    action: 'New Quote',
                    user,
                });
            }
        } catch (e) {
            console.error(e);
        }

        navigate(ROUTES.newQuote);
    }, [user, navigate, newQuoteClickMutation]);

    return (
        <GridToolbarContainer>
            <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} width='100%'>
                <Box sx={{ paddingTop: 1, paddingLeft: 1 }}>
                    <GridToolbarColumnsButton />
                    <GridToolbarFilterButton />
                </Box>
                {divisionEnabled.length === 1 && (
                    <Box sx={{ paddingTop: 1, paddingLeft: 1, marginRight: '16px!important' }}>
                        <Button onClick={navigateToNewQuote} startIcon={<Add />} color='primary' variant='contained'>
                            New Quote
                        </Button>
                    </Box>
                )}
            </Stack>
        </GridToolbarContainer>
    );
}
