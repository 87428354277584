import { Email, IFilter, LogicalOperator, Operator } from '@price-for-profit/micro-services';
import { PRODUCT_KEY_SEED } from 'shared/constants';
import {
    WANDS_DIVISION,
    HPC_DIVISION,
    FANDB_DIVISION,
    IDivisionSourceLookup,
    GLOBETEK_ERP,
    RADIUS_ERP,
    IProductLookup,
    OTHER_DIVISION,
    IQuote,
    CLIENT_ID,
} from 'shared/types';
import { getGridDateOperators } from '@mui/x-data-grid-premium';

export function buildEmail({
    subject,
    body,
    to,
    cc,
}: {
    subject: string;
    body: string;
    to: string[];
    cc: string[];
}): Email {
    return {
        actor: 'MCC Drive Application',
        clientId: CLIENT_ID,
        subject,
        body,
        to,
        cc,
        bcc: [],
    };
}

export function getBusinessUnitLookupValueFromBusinessUnitSelect(businessUnit: string): string {
    const lowerCaseBusinessUnit = businessUnit?.toLowerCase();
    return lowerCaseBusinessUnit
        ? lowerCaseBusinessUnit.includes(WANDS_DIVISION.toLowerCase())
            ? WANDS_DIVISION
            : lowerCaseBusinessUnit.includes(HPC_DIVISION.toLowerCase())
            ? HPC_DIVISION
            : FANDB_DIVISION
        : '';
}

export function getNoUserOptionsQuery() {
    return {
        property: 'source' as keyof IProductLookup,
        operator: 'eq' as Operator,
        value: 'XXXX', // if somehow we have no user, we should not be showing options
    };
}

export function getBusinessUnitQueryWithPossibleQuerySearchParams(
    businessUnitQueryValue: string,
    searchValue: string,
    user: drive.UserInfo,
    isRadius: boolean
): IFilter<IProductLookup> {
    const erp = isRadius ? RADIUS_ERP : GLOBETEK_ERP;

    return searchValue
        ? {
              logicalOperator: 'and' as LogicalOperator,
              filters: [
                  {
                      property: 'division' as keyof IProductLookup,
                      operator: 'eq' as Operator,
                      value: businessUnitQueryValue.toLowerCase(),
                  },
                  {
                      property: 'source' as keyof IProductLookup,
                      operator: 'eq' as Operator,
                      value: erp.toLowerCase(),
                  },
                  {
                      property: 'productlookup' as keyof IProductLookup,
                      operator: 'contains' as Operator,
                      value: searchValue.toLowerCase(),
                  },
              ],
          }
        : {
              logicalOperator: 'and' as LogicalOperator,
              filters: [
                  {
                      property: 'division' as keyof IProductLookup,
                      operator: 'eq' as Operator,
                      value: businessUnitQueryValue.toLowerCase(),
                  },
                  {
                      property: 'source' as keyof IProductLookup,
                      operator: 'eq' as Operator,
                      value: erp.toLowerCase(),
                  },
              ],
          };
}

export function getPermissionAndBusinessUnitBasedCollectionFilterWithPossibleQuerySearchParams(
    searchValue: string,
    user: drive.UserInfo,
    isRadius: boolean,
    isFandB: boolean,
    isWandS: boolean
): IFilter<IProductLookup> {
    const erp = isRadius ? RADIUS_ERP : GLOBETEK_ERP;
    const userDivision = isWandS ? WANDS_DIVISION : isFandB ? FANDB_DIVISION : HPC_DIVISION;

    return searchValue
        ? {
              logicalOperator: 'and' as LogicalOperator,
              filters: [
                  {
                      property: 'division' as keyof IProductLookup,
                      operator: 'eq' as Operator,
                      value: userDivision.toLowerCase(),
                  },
                  {
                      property: 'source' as keyof IProductLookup,
                      operator: 'eq' as Operator,
                      value: erp.toLowerCase(),
                  },
                  {
                      property: 'productlookup' as keyof IProductLookup,
                      operator: 'contains' as Operator,
                      value: searchValue.toLowerCase(),
                  },
                  {
                      property: 'division' as keyof IProductLookup,
                      operator: 'ne' as Operator,
                      value: OTHER_DIVISION.toLowerCase(),
                  },
              ],
          }
        : {
              logicalOperator: 'and' as LogicalOperator,
              filters: [
                  {
                      property: 'division' as keyof IProductLookup,
                      operator: 'eq' as Operator,
                      value: userDivision.toLowerCase(),
                  },
                  {
                      property: 'source' as keyof IProductLookup,
                      operator: 'eq' as Operator,
                      value: erp.toLowerCase(),
                  },
                  {
                      property: 'division' as keyof IProductLookup,
                      operator: 'ne' as Operator,
                      value: OTHER_DIVISION.toLowerCase(),
                  },
              ],
          };
}

export function getPermissionAndBusinessUnitBasedCollectionFilterForDuplicates(
    businessUnit: string,
    user: drive.UserInfo | null,
    isRadius: boolean,
    isFandB: boolean,
    isWandS: boolean,
    userLowerCaseSearch?: boolean
): IFilter<IDivisionSourceLookup> | undefined {
    const businessUnitQueryValue = getBusinessUnitLookupValueFromBusinessUnitSelect(businessUnit);
    const erp = isRadius ? RADIUS_ERP : GLOBETEK_ERP;
    const userDivision = isWandS ? WANDS_DIVISION : isFandB ? FANDB_DIVISION : HPC_DIVISION;

    return user
        ? businessUnitQueryValue
            ? {
                  logicalOperator: 'and',
                  filters: [
                      {
                          property: 'division',
                          operator: 'eq',
                          value: userLowerCaseSearch ? businessUnitQueryValue.toLowerCase() : businessUnitQueryValue,
                      },
                      {
                          property: 'source',
                          operator: 'eq',
                          value: userLowerCaseSearch ? erp.toLowerCase() : erp,
                      },
                      {
                          property: 'division',
                          operator: 'ne' as Operator,
                          value: OTHER_DIVISION.toLowerCase(),
                      },
                  ],
              }
            : {
                  logicalOperator: 'and',
                  filters: [
                      {
                          property: 'division',
                          operator: 'eq',
                          value: userLowerCaseSearch ? userDivision.toLowerCase() : userDivision,
                      },
                      {
                          property: 'source',
                          operator: 'eq',
                          value: userLowerCaseSearch ? erp.toLowerCase() : erp,
                      },
                      {
                          property: 'division',
                          operator: 'ne' as Operator,
                          value: OTHER_DIVISION.toLowerCase(),
                      },
                  ],
              }
        : {
              property: 'source',
              operator: 'eq',
              value: 'XXXX', // if somehow we have no user, we should not be showing options
          };
}

export function getPermissionAndBusinessUnitBasedCollectionFilterForUnique(
    businessUnit: string,
    user: drive.UserInfo | null,
    isRadius: boolean,
    isWandS: boolean,
    userLowerCaseSearch?: boolean
): IFilter<IDivisionSourceLookup> | undefined {
    const businessUnitQueryValue = getBusinessUnitLookupValueFromBusinessUnitSelect(businessUnit);
    const erp = isRadius ? RADIUS_ERP : GLOBETEK_ERP;

    return user
        ? businessUnitQueryValue
            ? {
                  logicalOperator: 'and',
                  filters: [
                      {
                          property: 'division',
                          operator: 'eq',
                          value: userLowerCaseSearch ? businessUnitQueryValue.toLowerCase() : businessUnitQueryValue,
                      },
                      {
                          property: 'source',
                          operator: 'eq',
                          value: userLowerCaseSearch ? erp.toLowerCase() : erp,
                      },
                      {
                          property: 'division',
                          operator: 'ne' as Operator,
                          value: OTHER_DIVISION.toLowerCase(),
                      },
                  ],
              }
            : isRadius
            ? {
                  logicalOperator: 'and',
                  filters: [
                      {
                          property: 'division',
                          operator: isWandS ? 'eq' : 'ne',
                          value: WANDS_DIVISION.toLowerCase(),
                      },
                      {
                          property: 'source',
                          operator: 'eq',
                          value: RADIUS_ERP.toLowerCase(),
                      },
                      {
                          property: 'division',
                          operator: 'ne' as Operator,
                          value: OTHER_DIVISION.toLowerCase(),
                      },
                  ],
              }
            : {
                  logicalOperator: 'and',
                  filters: [
                      {
                          property: 'source',
                          operator: 'eq',
                          value: GLOBETEK_ERP.toLowerCase(),
                      },
                      {
                          property: 'division',
                          operator: 'ne' as Operator,
                          value: OTHER_DIVISION.toLowerCase(),
                      },
                  ],
              }
        : {
              property: 'source',
              operator: 'eq',
              value: 'XXXX', // if somehow we have no user, we should not be showing options
          };
}

export const initializeProductKeys = (quote: IQuote) => {
    quote.products.forEach((p, index) => {
        const productNumber = PRODUCT_KEY_SEED + index;
        p.productKey = quote.quoteNumber + '-' + productNumber.toString().padStart(4, '0'); // 1111111-0001
    });
};

export function browserDownloadCsv(csv: string, fileName: string) {
    const blobUrl = URL.createObjectURL(new Blob([csv]));
    const link = document.createElement('a');

    link.href = blobUrl;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(link.href);
    link.parentNode?.removeChild(link);
}

export function cleanStringData(stringToClean?: string): string {
    return stringToClean?.trim().replaceAll('\t', '') || '';
}

export function handleBlob(blob: Blob, fileName: string) {
    const blobUrl = URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');
    link.href = blobUrl;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(link.href);
    link.parentNode?.removeChild(link);
}

export function loadDateOperator(operator: string) {
    switch (operator) {
        case 'is':
            return '=';
        case 'not':
            return '!=';
        case 'after':
            return '>';
        case 'onOrAfter':
            return '>=';
        case 'before':
            return '<';
        case 'onOrBefore':
            return '<=';
        case 'isEmpty':
            return 'IS NULL';
        case 'isNotEmpty':
            return 'IS NOT NULL';
        default: {
            return operator.toLowerCase();
        }
    }
}

export function getLimitedGridDateOperators() {
    return getGridDateOperators().filter(operator => operator.value !== 'is' && operator.value !== 'not');
}
