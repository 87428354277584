import { AppPermission, LoginPermissionKey } from '@price-for-profit/auth-types';
import { ISelectOption } from 'shared/types';

type PermissionKey = LoginPermissionKey | 'radius' | 'globetek' | 'wands' | 'fandb' | 'hpc';

export const PERMISSIONS: Record<PermissionKey, AppPermission<PermissionKey>> = {
    login: 'mcc.login',
    radius: 'mcc.radius',
    globetek: 'mcc.globetek',
    wands: 'mcc.wands',
    fandb: 'mcc.fandb',
    hpc: 'mcc.hpc',
};

export const DIVISION_OPTIONS: ISelectOption[] = [
    { key: 'mcc.wands', value: 'W&S' },
    { key: 'mcc.fandb', value: 'F&B' },
    { key: 'mcc.hpc', value: 'HPC' },
];

export const ERP_OPTIONS: ISelectOption[] = [
    { key: 'mcc.radius', value: 'Radius' },
    { key: 'mcc.globetek', value: 'Globe-Tek' },
];
