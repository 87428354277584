import { useQuery } from '@tanstack/react-query';
import { IKeyCompetitorLookup } from 'shared/types';
import { QUERY_KEYS } from 'shared/constants';
import { useService } from '@price-for-profit/service-provider';

export function useKeyCompetitorSearch(
    query: string,
    user: drive.UserInfo | null,
    isRadius: boolean,
    isFandB: boolean,
    isWandS: boolean
) {
    const { quoteService } = useService();
    const initialData: IKeyCompetitorLookup[] = [];
    const { data, isFetching, isLoading } = useQuery<IKeyCompetitorLookup[], Error>({
        queryKey: [QUERY_KEYS.competitor, query],
        queryFn: () => quoteService.getKeyCompetitor(query, user, isRadius, isFandB, isWandS),
        initialData,
        enabled: Boolean(query),
    });

    return { data, isFetching, isLoading } as const;
}
