import { NewQuote } from './new';
import { useService } from '@price-for-profit/service-provider';
import { useUser } from '@price-for-profit/auth';
import { useTrackPageEvent } from '@price-for-profit/user-activity-tracking';

export function NewQuoteResolver() {
    const { appConfig } = useService();
    const user = useUser();

    useTrackPageEvent({
        action: 'NewQuotePage',
        clientId: appConfig.clientId,
        user,
    });

    return <NewQuote />;
}
