import { useService } from '@price-for-profit/service-provider';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { IQuote, TQuoteWithoutId } from 'shared/types';

export function useCreateQuote() {
    const { quoteService } = useService();
    const { enqueueSnackbar } = useSnackbar();
    const mutation = useMutation<IQuote, Error, TQuoteWithoutId>({
        mutationFn: request => quoteService.createQuote(request),
        onSuccess: () => {
            enqueueSnackbar('Quote was created', { variant: 'success' });
        },
        onError: () => {
            enqueueSnackbar('Quote was not created', { variant: 'error' });
        },
    });

    return mutation;
}
