import { ICustomerLookup } from 'shared/types';
import { useService } from '@price-for-profit/service-provider';
import { useQuery } from '@tanstack/react-query';

export function useCustomerSearch(
    queryKey: string,
    query: string,
    user: drive.UserInfo | null,
    isRadius: boolean,
    isWandS: boolean
) {
    const { quoteService } = useService();
    const initialData: ICustomerLookup[] = [];
    const { data, isFetching, isLoading } = useQuery<ICustomerLookup[], Error>({
        queryKey: [queryKey, query],
        queryFn: () => quoteService.getCustomerData(query, user, isRadius, isWandS),
        initialData,
        enabled: Boolean(query),
    });

    return { data, isFetching, isLoading } as const;
}
