import { red } from '@mui/material/colors';

export const PRODUCT_SUMMARY_STYLES = {
    dataGridBoxContainerStyle: {
        'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
        },
        '.MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bold',
        },
        '& .drive-app-wps--cell--editable': {
            bgcolor: '#dbe7f2',
        },
    },
    dataGridStyle: {
        '& .MuiDataGrid-main': {
            borderTop: '1px solid #DDD',
            marginTop: '10px',
        },
        '& .MuiDataGrid-virtualScroller': {
            overflowY: 'scroll !important',
            maxHeight: '50vh',
            border: '1px solid #DDDDDD',
            borderTop: 'none',
            // marginTop: '56px !important',
        },
        '& .MuiDataGrid-virtualScrollerContent': {
            height: '45px!important',
        },
        '& .MuiDataGrid-row, & .MuiDataGrid-cell': {
            minHeight: '45px !important',
            maxHeight: '45px !important',
            height: '45px',
            lineHeight: '45px',
        },
        '& .MuiDataGrid-columnHeaders': {
            marginTop: '0',
        },
        '& .MuiDataGrid-columnHeaderTitleContainer': {
            overflow: 'visible',
        },
        '& .lead-group-header': {
            '& .MuiDataGrid-columnSeparator, & .MuiDataGrid-iconButtonContainer, & .MuiDataGrid-menuIcon': {
                // marginTop: '40px',
                marginTop: '0px',
            },
        },
        '& .grouped-header': {
            // marginTop: '20px',
            marginTop: '0px',
            '& .MuiDataGrid-columnSeparator': {
                marginTop: '0',
            },
        },
        '& .high-margin': {
            backgroundColor: `${red[100]}`,
            '&:hover': {
                backgroundColor: `${red[50]}`,
            },
        },
        '& .MuiDataGrid-cell--editable': {
            minHeight: '45px !important',
            maxHeight: '45px !important',
            height: '45px',
            '& .MuiDataGrid-cellContent, & span': {
                border: '1px solid #CCCCCC',
                borderRadius: '0.2em',
                padding: '5px',
                margin: '0',
                width: '100%',
                height: '80%',
            },
            '& span': {
                textAlign: 'center',
            },
        },
    },
};

export const PRODUCT_FIELDS_STYLES = {
    boxStyle: {
        paddingTop: '10px',
        margin: 'auto',
    },
    modalTitleStyle: {
        paddingTop: '5px',
    },
};

export const PRODUCT_DASHBOARD_MODAL_STYLES = {
    subtitleStyle: {
        fontSize: '1em',
        textAlign: 'center',
        color: 'rgba(0, 0, 0, 0.8)',
    },
    tabHeaderStyle: {
        borderBottom: 1,
        borderColor: 'divider',
    },
};

export const PRODUCT_MASS_ACTION_MODAL_STYLES = {
    formContainerStyle: {
        display: 'flex',
        flexDirection: 'column',
        pt: 2,
        gap: 2,
    },
};

export const PLEASE_WAIT_MODAL_STYLES = {
    titleStyle: {
        fontSize: '1em',
        textAlign: 'center',
    },
    subtitleStyle: {
        margin: '20px 0',
        fontSize: '1em',
        textAlign: 'center',
        color: 'rgba(0, 0, 0, 0.8)',
    },
};
