import { useQuery } from '@tanstack/react-query';
import { IProductMarginHistogram } from 'shared/types';
import { QUERY_KEYS } from 'shared/constants';
import { useService } from '@price-for-profit/service-provider';

export function useProductHistogramData(peerGroup: string) {
    const { productService } = useService();
    const initialData: IProductMarginHistogram[] = [];
    const { data, isFetching, isLoading } = useQuery<IProductMarginHistogram[], Error>({
        queryKey: [QUERY_KEYS.productHistogram, peerGroup],
        queryFn: () => productService.getProductHistogramData(peerGroup),
        initialData,
    });

    if (!data) {
        return { data: initialData, isFetching, isLoading } as const;
    }

    return { data, isFetching, isLoading } as const;
}
