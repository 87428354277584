import { Autocomplete, Box, TextField } from '@mui/material';
import { GridRenderCellParams, useGridApiContext } from '@mui/x-data-grid-premium';
import { useState } from 'react';
import { ProductForm } from '../../types';

interface IGridSelectProps {
    id?: string;
    data: string[];
    isLoading: boolean;
    isFetching: boolean;
    params: GridRenderCellParams<ProductForm, string>;
}

export function GridSelect({ id = 'autocomplete', data, isLoading, isFetching, params }: IGridSelectProps) {
    const apiRef = useGridApiContext();
    const [inputValue, setInputValue] = useState(params.value);

    const handleValueChange = (event: React.SyntheticEvent<Element, Event>, newValue: any) => {
        apiRef.current.setEditCellValue({ id: params.id, field: params.field, value: newValue });
    };

    const handleInputChange = (event: React.SyntheticEvent<Element, Event>, newValue: any) => {
        setInputValue(newValue);
    };

    if (data !== undefined || isLoading || isFetching) {
        return (
            <Autocomplete
                id={id}
                options={data || []}
                defaultValue={inputValue}
                getOptionLabel={option => (option || '').toUpperCase()}
                sx={{ width: 300 }}
                size='small'
                onChange={handleValueChange}
                onInputChange={handleInputChange}
                renderInput={params => <TextField {...params} />}
            />
        );
    } else {
        return <Box>Loading...</Box>;
    }
}
