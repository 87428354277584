import { useToggle } from '@price-for-profit/react-hooks';
import { QueryKey } from '@tanstack/react-query';
import { createContext, PropsWithChildren, useContext, useState } from 'react';
import { IQuote } from 'shared/types';

interface QuoteDeleteDialogContext {
    deleteDialogOpen: boolean;
    quoteToDelete: IQuote | null;
    quotesQueryKey: QueryKey | undefined;
    toggleDeleteQuoteDialog: () => void;
    setQuoteToDelete: (quote: IQuote) => void;
    setQuotesQueryKey: (queryKey: QueryKey) => void;
}

const quoteDeleteDialogContext = createContext({} as QuoteDeleteDialogContext);

export function ProvideQuoteState({ children }: PropsWithChildren<{}>) {
    const [deleteDialogOpen, toggleDeleteQuoteDialog] = useToggle();
    const [quoteToDelete, setQuoteToDelete] = useState<IQuote | null>(null);
    const [quotesQueryKey, setQuotesQueryKey] = useState<QueryKey | undefined>();

    return (
        <quoteDeleteDialogContext.Provider
            value={{
                deleteDialogOpen,
                quoteToDelete,
                quotesQueryKey,
                toggleDeleteQuoteDialog,
                setQuoteToDelete,
                setQuotesQueryKey,
            }}
        >
            {children}
        </quoteDeleteDialogContext.Provider>
    );
}

export function useQuoteDeleteDialogState() {
    return useContext(quoteDeleteDialogContext);
}
