import { generateCollectionFilter, InsightGridColDef, ServerSideState } from '@price-for-profit/data-grid';
import {
    CalculationPayload,
    CalculationResponse,
    DataAccessPaginatedResponse,
    getData,
    ICalculationService,
    IDataAccessService,
    IExportService,
    IFilter,
    NoInfer,
    Operator,
} from '@price-for-profit/micro-services';
import {
    DATASTORE_LOOKUP,
    LOOKUP_TABLES,
    DOCUMENT_TABLES,
    DATASTORE_DOCUMENT,
    APPROVAL_TO_EMAILS,
    APPROVAL_CC_EMAILS,
    APPROVAL_EMAIL_TEMPLATES,
} from 'shared/constants';
import {
    ICustomerLookup,
    ICustomerTierLookup,
    IEndMarketLookup,
    IBusinessUnitLookup,
    ISalesRepNameLookup,
    TQuoteWithoutId,
    IQuote,
    ISendApprovalNotificationProps,
    WANDS_DIVISION,
    GLOBETEK_ERP,
    RADIUS_ERP,
    QuoteStatusEnum,
    IDownloadFileProps,
    IKeyCompetitorLookup,
    FANDB_DIVISION,
    HPC_DIVISION,
    OTHER_DIVISION,
    CLIENT_ID,
    QuoteSizeAddder,
} from 'shared/types';
import {
    buildEmail,
    formatFilterItemsValue,
    getPermissionAndBusinessUnitBasedCollectionFilterForDuplicates,
    handleBlob,
    loadDateOperator,
} from 'shared/utility';
import { INotificationService } from './notification-service';
import { GridFilterModel } from '@mui/x-data-grid-premium';

export interface CalculateProps<T> {
    modelId: string;
    payload: CalculationPayload<T>;
}

export interface IQuoteService {
    getPreviousQuotes(currentQuote: IQuote): Promise<DataAccessPaginatedResponse<IQuote>>;
    getGridData(
        state: ServerSideState,
        columns: InsightGridColDef<IQuote>[],
        isPricingLeadership?: boolean
    ): Promise<DataAccessPaginatedResponse<IQuote>>;
    getCustomerData(
        query: string,
        user: drive.UserInfo | null,
        isRadius: boolean,
        isWandS: boolean
    ): Promise<ICustomerLookup[]>;
    getKeyCompetitor(
        query: string,
        user: drive.UserInfo | null,
        isRadius: boolean,
        isFandB: boolean,
        isWandS: boolean
    ): Promise<IKeyCompetitorLookup[]>;
    getCustomerTiers(): Promise<ICustomerTierLookup[]>;
    getCustomerEndMarkets(
        businessUnit: string,
        user: drive.UserInfo | null,
        isRadius: boolean,
        isFandB: boolean,
        isWandS: boolean
    ): Promise<IEndMarketLookup[]>;
    getCustomerBusinessUnits(
        user: drive.UserInfo | null,
        isRadius: boolean,
        isWandS: boolean
    ): Promise<IBusinessUnitLookup[]>;
    getSalesRepNames(
        businessUnit: string,
        user: drive.UserInfo | null,
        isRadius: boolean,
        isFandB: boolean,
        isWandS: boolean
    ): Promise<ISalesRepNameLookup[]>;
    createQuote(quote: TQuoteWithoutId): Promise<IQuote>;
    getQuote(quoteId: string): Promise<IQuote>;
    updateQuote(quote: IQuote): Promise<IQuote>;
    downloadExcel<T>(props: IDownloadFileProps<T>): Promise<void>;
    calculate<I, O>(props: CalculateProps<I>): Promise<CalculationResponse<O>>;
    sendRequestApprovalQuoteNotification(props: ISendApprovalNotificationProps): Promise<void>;
    getQuoteSizeAdder(): Promise<QuoteSizeAddder[]>;
}

export class QuoteService implements IQuoteService {
    constructor(
        private calculationService: ICalculationService,
        private dasService: IDataAccessService,
        private notificationService: INotificationService,
        private exportService: IExportService
    ) {}

    async getPreviousQuotes(currentQuote: IQuote): Promise<DataAccessPaginatedResponse<IQuote>> {
        return await this.dasService.getCollection<IQuote, typeof DATASTORE_DOCUMENT>({
            clientId: CLIENT_ID,
            databaseLabel: DATASTORE_DOCUMENT,
            tableId: DOCUMENT_TABLES.quote,
            page: 0,
            pageSize: 1,
            sortBy: 'createdOn',
            sortDescending: true,
            collectionFilter: {
                property: 'createdOn',
                operator: 'lt',
                value: currentQuote.createdOn,
            },
        });
    }

    async getGridData(
        state: ServerSideState,
        columns: InsightGridColDef<IQuote>[],
        isPricingLeadership?: boolean
    ): Promise<DataAccessPaginatedResponse<IQuote>> {
        const numericColumns = columns.filter(c => c.type === 'number').map(c => c.field);
        const dateColumns = columns.filter(c => c.field.includes('date')).map(c => c.field);

        // we store uppercase values in the db
        const filterModel: GridFilterModel = {
            ...state.filterModel,
            items:
                state.filterModel.items?.map(item => ({
                    ...item,
                    value: formatFilterItemsValue(item, numericColumns, dateColumns),
                    operatorValue:
                        dateColumns.includes(item.field) && item.operator
                            ? loadDateOperator(item.operator)
                            : item.operator,
                })) || [],
        };

        filterModel.items.push({
            field: 'status',
            operator: '!=',
            value: QuoteStatusEnum.DELETED,
        });

        if (isPricingLeadership) {
            filterModel.items.push({
                field: 'status',
                operator: '!=',
                value: QuoteStatusEnum.DRAFT,
            });
        }

        return await this.dasService.getCollection<IQuote, typeof DATASTORE_DOCUMENT>({
            clientId: CLIENT_ID,
            databaseLabel: DATASTORE_DOCUMENT,
            tableId: DOCUMENT_TABLES.quote,
            page: state.paginationModel.page,
            pageSize: state.paginationModel.pageSize,
            sortBy: state.sortModel[0]?.field as NoInfer<keyof IQuote>,
            sortDescending: state.sortModel[0]?.sort === 'desc',
            collectionFilter: generateCollectionFilter<IQuote>(filterModel),
        });
    }

    async getCustomerData(
        query: string,
        user: drive.UserInfo | null,
        isRadius: boolean,
        isWandS: boolean
    ): Promise<ICustomerLookup[]> {
        let collectionFilter: IFilter<ICustomerLookup> | undefined = query
            ? isRadius // only radius users should be filtered by division
                ? {
                      logicalOperator: 'and',
                      filters: [
                          {
                              property: 'division',
                              operator: isWandS ? 'eq' : 'ne', // HPC and F&B users can see each others stuff
                              value: WANDS_DIVISION.toLowerCase(), // this fix is temporally until we confirm the case of the data
                          },
                          {
                              property: 'commoncustomername',
                              operator: 'contains',
                              value: query.toLowerCase(), // this fix is temporally until we confirm the case of the data
                          },
                      ],
                  }
                : {
                      property: 'commoncustomername',
                      operator: 'contains',
                      value: query.toLowerCase(), // this fix is temporally until we confirm the case of the data
                  }
            : undefined;

        const { data } = await this.dasService.getCollection<ICustomerLookup, typeof DATASTORE_LOOKUP>({
            clientId: CLIENT_ID,
            databaseLabel: DATASTORE_LOOKUP,
            tableId: LOOKUP_TABLES.customerLookup,
            collectionFilter,
        });
        return data;
    }

    async getKeyCompetitor(
        query: string,
        user: drive.UserInfo | null,
        isRadius: boolean,
        isFandB: boolean,
        isWandS: boolean
    ): Promise<IKeyCompetitorLookup[]> {
        const userDivision = isWandS ? WANDS_DIVISION : isFandB ? FANDB_DIVISION : HPC_DIVISION;
        const erp = isRadius ? RADIUS_ERP : GLOBETEK_ERP;
        const collectionFilter: IFilter<IKeyCompetitorLookup> | undefined = {
            logicalOperator: 'and',
            filters: [
                {
                    property: 'division',
                    operator: 'eq',
                    value: userDivision,
                },
                {
                    property: 'source',
                    operator: 'eq',
                    value: erp,
                },
                {
                    property: 'division',
                    operator: 'ne' as Operator,
                    value: OTHER_DIVISION.toLowerCase(),
                },
                {
                    property: 'displayname',
                    operator: 'contains',
                    value: query.toUpperCase(),
                },
            ],
        };

        const { data } = await this.dasService.getCollection<IKeyCompetitorLookup, typeof DATASTORE_LOOKUP>({
            clientId: CLIENT_ID,
            databaseLabel: DATASTORE_LOOKUP,
            tableId: LOOKUP_TABLES.keyCompetitorLookup,
            collectionFilter,
        });
        return data;
    }

    async getCustomerTiers(): Promise<ICustomerTierLookup[]> {
        const data = await getData(page =>
            this.dasService.getCollection<ICustomerTierLookup, typeof DATASTORE_LOOKUP>({
                clientId: CLIENT_ID,
                databaseLabel: DATASTORE_LOOKUP,
                tableId: LOOKUP_TABLES.customerTierLookup,
                page,
                pageSize: 100,
            })
        );
        return data;
    }

    async getCustomerEndMarkets(
        businessUnit: string,
        user: drive.UserInfo | null,
        isRadius: boolean,
        isFandB: boolean,
        isWandS: boolean
    ): Promise<IEndMarketLookup[]> {
        const collectionFilter = getPermissionAndBusinessUnitBasedCollectionFilterForDuplicates(
            businessUnit,
            user,
            isRadius,
            isFandB,
            isWandS
        );

        const data = await getData(page =>
            this.dasService.getCollection<IEndMarketLookup, typeof DATASTORE_LOOKUP>({
                clientId: CLIENT_ID,
                databaseLabel: DATASTORE_LOOKUP,
                tableId: LOOKUP_TABLES.endMarketLookup,
                page,
                pageSize: 100,
                collectionFilter,
            })
        );
        return data;
    }

    async getCustomerBusinessUnits(
        user: drive.UserInfo | null,
        isRadius: boolean,
        isWandS: boolean
    ): Promise<IBusinessUnitLookup[]> {
        const collectionFilter: IFilter<IBusinessUnitLookup> | undefined = isRadius
            ? {
                  logicalOperator: 'and',
                  filters: [
                      {
                          property: 'division',
                          operator: isWandS ? 'eq' : 'ne',
                          value: WANDS_DIVISION,
                      },
                      {
                          property: 'source',
                          operator: 'eq',
                          value: RADIUS_ERP,
                      },
                  ],
              }
            : {
                  property: 'source',
                  operator: 'eq',
                  value: GLOBETEK_ERP,
              };

        const data = await getData(page =>
            this.dasService.getCollection<IBusinessUnitLookup, typeof DATASTORE_LOOKUP>({
                clientId: CLIENT_ID,
                databaseLabel: DATASTORE_LOOKUP,
                tableId: LOOKUP_TABLES.businessUnitLookup,
                page,
                pageSize: 100,
                collectionFilter,
            })
        );
        return data;
    }

    async getSalesRepNames(
        businessUnit: string,
        user: drive.UserInfo | null,
        isRadius: boolean,
        isFandB: boolean,
        isWandS: boolean
    ): Promise<ISalesRepNameLookup[]> {
        const collectionFilter = getPermissionAndBusinessUnitBasedCollectionFilterForDuplicates(
            businessUnit,
            user,
            isRadius,
            isFandB,
            isWandS
        );

        const data = await getData(page =>
            this.dasService.getCollection<ISalesRepNameLookup, typeof DATASTORE_LOOKUP>({
                clientId: CLIENT_ID,
                databaseLabel: DATASTORE_LOOKUP,
                tableId: LOOKUP_TABLES.salesRepNameLookup,
                page,
                pageSize: 100,
                collectionFilter,
            })
        );
        return data;
    }

    async getQuote(quoteId: string): Promise<IQuote> {
        const { data } = await this.dasService.getSingle<IQuote, typeof DATASTORE_DOCUMENT>({
            clientId: CLIENT_ID,
            databaseLabel: DATASTORE_DOCUMENT,
            tableId: DOCUMENT_TABLES.quote,
            key: quoteId,
        });
        return data as IQuote;
    }

    async getQuoteSizeAdder(): Promise<QuoteSizeAddder[]> {
        const { data } = await this.dasService.getCollection<any, typeof DATASTORE_LOOKUP>({
            clientId: CLIENT_ID,
            databaseLabel: DATASTORE_LOOKUP,
            tableId: LOOKUP_TABLES.quoteSizeAdder,
        });
        return data;
    }

    async createQuote(quote: TQuoteWithoutId): Promise<IQuote> {
        const { data } = await this.dasService.addRow<TQuoteWithoutId, typeof DATASTORE_DOCUMENT>({
            clientId: CLIENT_ID,
            databaseLabel: DATASTORE_DOCUMENT,
            tableId: DOCUMENT_TABLES.quote,
            payload: quote,
        });
        return data as IQuote;
    }

    async updateQuote(quote: IQuote): Promise<IQuote> {
        const { data } = await this.dasService.updateRow<IQuote, typeof DATASTORE_DOCUMENT>({
            clientId: CLIENT_ID,
            databaseLabel: DATASTORE_DOCUMENT,
            tableId: DOCUMENT_TABLES.quote,
            payload: quote,
        });
        return data as IQuote;
    }

    async downloadExcel<T>({ exportId = 'quote-export', data, fileName = 'export' }: IDownloadFileProps<T>) {
        const blob = await this.exportService.downloadExcel<T>({
            clientId: CLIENT_ID,
            templateId: exportId,
            payload: data,
        });
        handleBlob(blob, `${fileName}.xlsx`);
    }

    async calculate<I, O>({ modelId, payload }: CalculateProps<I>) {
        return await this.calculationService.calculate<I, O>({ clientId: CLIENT_ID, modelId, payload });
    }

    async sendRequestApprovalQuoteNotification({
        environment,
        division,
        quoteId,
        quoteNumber,
        customerName,
        username,
    }: ISendApprovalNotificationProps): Promise<void> {
        const to: string[] = APPROVAL_TO_EMAILS[environment][division];
        const cc: string[] = APPROVAL_CC_EMAILS[environment];
        const { subject, body } = APPROVAL_EMAIL_TEMPLATES({
            quoteId,
            quoteNumber,
            customerName,
            username,
        }).requestApproval;

        const email = buildEmail({
            subject,
            body,
            to,
            cc,
        });
        await this.notificationService.sendEmail(email);
    }
}
