import {
    AxiosInstance,
    CalculationService,
    DataAccessService,
    EventService,
    ExportService,
} from '@price-for-profit/micro-services';
import { NavigationService } from '@price-for-profit/drive-account-navigation';
import { NotificationService, ProductService, QuoteService } from '../shared/services';
import { ActivityTrackingService } from '@price-for-profit/user-activity-tracking';

export function createServiceContext(appConfig: drive.AppConfig, httpClient: AxiosInstance): drive.ServiceContext {
    const eventService = new EventService(httpClient);
    const dasService = new DataAccessService(httpClient);
    const activityTrackingService = new ActivityTrackingService(eventService);
    const navigationService = new NavigationService(appConfig.clientId, dasService);
    const calculationService = new CalculationService(httpClient);
    const notificationService = new NotificationService(eventService);
    const exportService = new ExportService(httpClient);

    const quoteService = new QuoteService(calculationService, dasService, notificationService, exportService);
    const productService = new ProductService(dasService);

    return {
        appConfig,
        navigationService,
        activityTrackingService,
        dataAccessService: dasService,
        eventService,
        calculationService,
        notificationService,
        exportService,
        quoteService,
        productService,
    };
}
