import { gridExpandedSortedRowEntriesSelector, useGridApiContext } from '@mui/x-data-grid-premium';

export function useGridApiRows() {
    const apiRef = useGridApiContext();

    const selectedRows = [...apiRef.current.getSelectedRows()];

    const selectedRowIds = [...apiRef.current.getSelectedRows().keys()];

    const visibleRows = gridExpandedSortedRowEntriesSelector(apiRef);

    const visibleRowsIds = visibleRows.map(row => row.id);

    const clearSelectedRows = () => {
        apiRef.current.setRowSelectionModel([]);
    };

    return {
        selectedRows,
        visibleRows,
        selectedRowIdsJoined: selectedRowIds.join(','),
        visibleRowsIdsJoined: visibleRowsIds.join(','),
        selectedRowsCount: selectedRows.length,
        visibleRowsCount: visibleRows.length,
        clearSelectedRows,
    };
}
