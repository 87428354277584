import { IPublicClientApplication } from '@azure/msal-browser';
import { AuthenticationRequired, LoginLayout, LoginPage } from '@price-for-profit/auth';
import { DefaultUserInfo } from '@price-for-profit/auth-types';
import { Navigation, buildNavigationRoutes } from '@price-for-profit/drive-account-navigation';
import { UnauthorizedPage } from '@price-for-profit/drive-app-core';
import { ProvidersWrapper } from 'index';
import { InsightLogoWaterMark, insightLoginLogo, insightLogo, loginClientLogo } from 'logos';
import { Navigate, Outlet, createBrowserRouter } from 'react-router-dom';
import { ROUTES } from 'routing';
import { ProvidePermissionERPDivision, ProvideQuoteState } from '../shared/contexts';
import { QuoteResolver } from '../pages/quote/resolver';
import { NewQuoteResolver } from '../pages/quote/new/resolver';
import { EditQuoteResolver } from '../pages/quote/edit/resolver';
import { ProfileConfigurationResolver } from '../pages/profileConfiguration';

const isProd = process.env.NODE_ENV === 'production';

export function getRoutes(
    context: drive.ServiceContext,
    clientApp: IPublicClientApplication,
    baseUrl: string,
    getUserInfo: (user: DefaultUserInfo) => drive.UserInfo
) {
    return createBrowserRouter(
        [
            {
                element: <ProvidersWrapper context={context} clientApp={clientApp} getUserInfo={getUserInfo} />,
                children: [
                    {
                        element: <Login />,
                        children: [
                            {
                                path: ROUTES.login,
                                element: (
                                    <LoginPage
                                        tabTitle='Mcc quote Login'
                                        insightLoginLogo={insightLoginLogo}
                                        clientLogo={loginClientLogo}
                                        loginRedirectRoute={ROUTES.quote}
                                    />
                                ),
                            },
                            {
                                path: ROUTES.unauthorized,
                                element: <Unauthorized />,
                            },
                        ],
                    },
                    {
                        element: <Private />,
                        children: [
                            buildNavigationRoutes({ clientId: context.appConfig.clientId, redirectRoute: ROUTES.demo }),
                            {
                                path: ROUTES.quote,
                                children: [
                                    {
                                        index: true,
                                        element: (
                                            <ProvideQuoteState>
                                                <QuoteResolver />
                                            </ProvideQuoteState>
                                        ),
                                    },
                                    {
                                        path: ROUTES.newQuote,
                                        element: <NewQuoteResolver />,
                                    },
                                    {
                                        path: ROUTES.editQuote,
                                        element: <EditQuoteResolver />,
                                    },
                                ],
                            },
                            {
                                path: ROUTES.profileConfiguration,
                                children: [{ index: true, element: <ProfileConfigurationResolver /> }],
                            },
                        ],
                    },
                    {
                        path: ROUTES.unauthorized,
                        element: <Unauthorized />,
                    },
                    {
                        path: '*',
                        element: <Navigate to={ROUTES.login} replace />,
                    },
                ],
            },
        ],
        {
            basename: baseUrl,
        }
    );
}

function Unauthorized() {
    return <UnauthorizedPage driveLogo={insightLoginLogo} />;
}

function Login() {
    return (
        <LoginLayout>
            <Outlet />
        </LoginLayout>
    );
}

function Private() {
    return (
        <AuthenticationRequired>
            <ProvidePermissionERPDivision>
                <Navigation
                    useLocal={!isProd}
                    logos={{
                        clientLogin: loginClientLogo,
                        insightWaterMark: <InsightLogoWaterMark />,
                        insight: insightLogo,
                    }}
                >
                    <Outlet />
                </Navigation>
            </ProvidePermissionERPDivision>
        </AuthenticationRequired>
    );
}
