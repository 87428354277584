import { Edit, Visibility, ContentCopy } from '@mui/icons-material';
import { InsightGridColDef } from '@price-for-profit/data-grid';
import { GridActionsCellItem, getGridStringOperators } from '@mui/x-data-grid-premium';
import { IQuote, TContractOrSpotBuy } from 'shared/types';
import { formatMoneyNoDecimals, getLimitedGridDateOperators } from 'shared/utility';
import { DeleteQuoteActionButton } from './delete-quote-action-button';
import { QueryKey } from '@tanstack/react-query';
import { CONTRACT_OR_SPOT_BUY_LABELS } from 'shared/constants';

interface IColumnsProps {
    navigateToNewQuoteCopyPage: (quote: IQuote) => void;
    navigateToEditPage: (quoteId: string) => void;
    handleDeleteClicked: (quote: IQuote, queryKey: QueryKey) => void;
    isPricingLeadership?: boolean;
}

export const getQuotesColumns = ({
    navigateToEditPage,
    isPricingLeadership,
    navigateToNewQuoteCopyPage,
    handleDeleteClicked,
}: IColumnsProps): InsightGridColDef<IQuote>[] => [
    {
        headerName: 'Actions',
        field: 'select',
        type: 'actions',
        width: 150,
        hideable: false,
        filterable: false,
        pinnable: false,
        getActions: params => {
            const actionCellItems = [
                <GridActionsCellItem
                    icon={isPricingLeadership ? <Visibility titleAccess='View' /> : <Edit titleAccess='Edit' />}
                    label='Edit'
                    color='primary'
                    onClick={() => navigateToEditPage(params.row.id)}
                />,
                <DeleteQuoteActionButton quote={params.row} handleDeleteClicked={handleDeleteClicked} />,
            ];

            if (!isPricingLeadership) {
                actionCellItems.push(
                    <GridActionsCellItem
                        icon={<ContentCopy titleAccess='Copy' />}
                        label='Copy'
                        color='primary'
                        onClick={() => navigateToNewQuoteCopyPage(params.row)}
                    />
                );
            }
            return actionCellItems;
        },
    },
    {
        headerName: 'Quote ID',
        field: 'quoteNumber',
        width: 200,
        type: 'number',
        renderCell: params => (params.value ? params.value : 'N/A'),
        valueParser: params => params.value && Number(params.value),
    },
    {
        headerName: 'Quote Date',
        field: 'date',
        width: 200,
        type: 'date',
        filterOperators: getLimitedGridDateOperators(),
        valueGetter: value => new Date(value),
    },
    {
        headerName: 'Status',
        field: 'status',
        width: 200,
    },
    {
        headerName: 'Customer Name',
        field: 'customerName',
        width: 200,
    },
    {
        headerName: 'Customer Tier',
        field: 'tier',
        width: 200,
    },
    {
        headerName: 'Sales Rep Name',
        field: 'salesRepName',
        width: 200,
    },
    {
        headerName: 'Deal Type',
        field: 'contractOrSpotBuy',
        width: 200,
        valueGetter: value => (value ? CONTRACT_OR_SPOT_BUY_LABELS[value as TContractOrSpotBuy] : ''),
    },
    {
        headerName: 'Recommended',
        field: 'recommendedAnnualRevenue',
        width: 150,
        type: 'number',
        renderCell: params =>
            params.value || params.value === 0 ? formatMoneyNoDecimals(Number(params.value) || 0) : 'N/A',
    },
    {
        headerName: 'Revised',
        field: 'revisedAnnualRevenue',
        width: 150,
        type: 'number',
        renderCell: params =>
            params.value || params.value === 0 ? formatMoneyNoDecimals(Number(params.value) || 0) : 'N/A',
    },
    {
        headerName: 'Delta',
        field: 'deltaAnnualRevenue',
        width: 150,
        type: 'number',
        renderCell: params =>
            params.value || params.value === 0 ? formatMoneyNoDecimals(Number(params.value) || 0) : 'N/A',
    },
    {
        headerName: 'Submitted By',
        field: 'createdBy',
        width: 150,
    },
    {
        headerName: 'Brand',
        field: 'brand',
        type: 'string',
        width: 200,
        valueGetter: (_, row) => row.mostUsedBrand,
        filterOperators: getGridStringOperators().filter(operator => operator.value === 'contains'),
    },
];
