import { useSnackbar } from 'notistack';
import { useMutation } from '@tanstack/react-query';
import { IProductErrorData, IProductErrorDataWithoutId } from 'shared/types';
import { useService } from '@price-for-profit/service-provider';

export function useCreateProductErrorData() {
    const { productService } = useService();
    const { enqueueSnackbar } = useSnackbar();
    const mutation = useMutation<IProductErrorData, Error, IProductErrorDataWithoutId>({
        mutationFn: request => productService.createProductErrorData(request),
        onSuccess: () => {
            enqueueSnackbar('Product upload errors have been logged', { variant: 'error' });
        },
        onError: () => {
            enqueueSnackbar('Could not log product errors', { variant: 'error' });
        },
    });

    return mutation;
}
