import { Button, CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import { parse, unparse } from 'papaparse';
import { useCallback, useState } from 'react';
import { LABEL_COMPLEXITIES, SIMILARITIES_TO_CUSTOMERS_EXISTING_LABELS } from 'shared/constants';
import { useFile } from 'shared/hooks';
import {
    useCustomerBusinessUnitSearch,
    useCustomerEndMarketSearch,
    useDecoratingTechnologySearch,
    usePrintingTechnologySearch,
    useRecommendedPlantSearch,
} from 'shared/queries';
import { useSubstrateSearch } from 'shared/queries/substrate-search';
import {
    CSVProduct,
    DivisionEnum,
    ERPEnum,
    ISetProductKeysProps,
    ProductForm,
    ReviewedEnum,
    TLabelComplexityKey,
    TSimilarityToCustomersExistingLabelKey,
} from 'shared/types';
import {
    browserDownloadCsv,
    cleanStringData,
    formatSlashDate,
    getDivisionByPermission,
    getERPByPermission,
} from 'shared/utility';
import { v4 as uuid } from 'uuid';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import UploadIcon from '@mui/icons-material/Upload';
import { PleaseWaitModal } from './modal/please-wait-modal';
import { usePermissionERPDivisionState } from 'shared/contexts';

interface MassUploadButtonsProps {
    setProductKey: ({ productToSetKey, products, quoteNumber }: ISetProductKeysProps) => void;
    watchProducts: ProductForm[];
    watchQuoteNumber: number;
    user: drive.UserInfo | null;
    handleParseComplete: (validProducts: ProductForm[], invalidProducts: CSVProduct[]) => void;
    setInvalidProducts: React.Dispatch<React.SetStateAction<CSVProduct[]>>;
    invalidProducts: CSVProduct[];
}

export function MassUploadButtons({
    setProductKey,
    watchProducts,
    watchQuoteNumber,
    user,
    handleParseComplete,
    invalidProducts,
}: MassUploadButtonsProps) {
    const [isLoading, setIsLoading] = useState(false);
    const { divisionEnabled, erpEnabled } = usePermissionERPDivisionState();

    const isRadius = getERPByPermission(erpEnabled) === ERPEnum.RADIUS;
    const isGlobeTek = getERPByPermission(erpEnabled) === ERPEnum.GLOBETEK;
    const isWandS = divisionEnabled.some(d => getDivisionByPermission(d) === DivisionEnum.WANDS);
    const isFandB = divisionEnabled.some(d => getDivisionByPermission(d) === DivisionEnum.FANDB);

    const { file, FileInput, clearFile, triggerFileSelect } = useFile('.csv');
    const {
        data: customerEndMarketData,
        isLoading: isEndMarketLoading,
        isFetching: isEndMarketFetching,
    } = useCustomerEndMarketSearch('', user, isRadius, isFandB, isWandS);
    const {
        data: recommendedPlantData,
        isLoading: isRecommendedPlantLoading,
        isFetching: isRecommendedPlantFetching,
    } = useRecommendedPlantSearch('', user, isRadius, isWandS);
    const {
        data: decoratingTechnologyData,
        isLoading: isDecoratingTechnologyLoading,
        isFetching: isDecoratingTechnologyFetching,
    } = useDecoratingTechnologySearch('', user, isRadius, isFandB, isWandS);
    const {
        data: substrateData,
        isLoading: isSubstrateDataLoading,
        isFetching: isSubstrateDataFetching,
    } = useSubstrateSearch('', user, isRadius, isFandB, isWandS);
    const {
        data: printingTechnologyData,
        isLoading: isPrintingTechnologyLoading,
        isFetching: isPrintingTechnologyFetching,
    } = usePrintingTechnologySearch('', user, isRadius, isFandB, isWandS);
    const {
        data: customerBusinessUnitData,
        isLoading: isBusinessUnitLoading,
        isFetching: isBusinessUnitFetching,
    } = useCustomerBusinessUnitSearch(user, isRadius, isWandS);

    const isValidOption = useCallback((options: string[], option: string, required?: boolean): boolean => {
        if (option) {
            const optionToValidateAgainst = cleanStringData(option);
            return options.includes(optionToValidateAgainst);
        }

        return !required;
    }, []);

    const transformCSVProductToProductForm = useCallback(
        (csvProduct: CSVProduct, index: number) => {
            const productToAdd: ProductForm = {
                productId: '',
                isNew: false,
                name: cleanStringData(csvProduct['Product Name']).toUpperCase(),
                number: cleanStringData(csvProduct['Product Number']),
                brand: cleanStringData(csvProduct.Brand).toUpperCase(),
                estimatedAnnualQuantity: cleanStringData(csvProduct['Estimated Annual Quantity']),
                estimatedCostPerUnit: cleanStringData(csvProduct['Estimated Cost Per Unit']),
                similarityToCustomersExistingLabel: cleanStringData(
                    csvProduct['Similarity To Customers Existing Label']
                ).toUpperCase() as TSimilarityToCustomersExistingLabelKey | '',
                labelComplexity: cleanStringData(csvProduct['Label Complexity']).toUpperCase() as
                    | TLabelComplexityKey
                    | '',
                endMarket: cleanStringData(csvProduct['End Market']).toUpperCase(),
                baselineMargin: '',
                recommendedPlant: cleanStringData(csvProduct['Recommended Plant']).toUpperCase(),
                businessUnit: cleanStringData(csvProduct['Business Unit']).toUpperCase(),
                recommendedPlantCode: '',
                decoratingTechnology:
                    cleanStringData(csvProduct['Recommended Plant']).toUpperCase() === 'ROCHESTER' || !isGlobeTek
                        ? cleanStringData(csvProduct['Decorating Technology']).toUpperCase()
                        : 'DO NOT USE - ROCHESTER ONLY',
                substrate:
                    cleanStringData(csvProduct['Recommended Plant']).toUpperCase() === 'ROCHESTER' && isGlobeTek
                        ? 'DO NOT USE FOR ROCHESTER'
                        : cleanStringData(csvProduct.Substrate).toUpperCase(),
                printingTechnology: cleanStringData(csvProduct['Printing Technology']).toUpperCase(),
                peerGroupPercentile: '',
                targetMargin: '',
                floorMargin: '',
                finalMargin: '',
                revisedPricePerUnit: '',
                peerGroup: '',
                baselineKey: '',
                uniqueId: uuid(),
                isReviewed: ReviewedEnum.NO,
            };

            setProductKey({
                productToSetKey: productToAdd,
                products: watchProducts,
                quoteNumber: watchQuoteNumber,
                index: index,
            });
            return productToAdd;
        },
        [watchProducts, watchQuoteNumber, setProductKey, isGlobeTek]
    );

    const isValidProduct = useCallback(
        (product: CSVProduct): boolean => {
            const isExistingLableValid = isValidOption(
                Object.entries(SIMILARITIES_TO_CUSTOMERS_EXISTING_LABELS).map(([k, v]) => v as string),
                (product['Similarity To Customers Existing Label'] || '').toUpperCase()
            );
            const isLableComplexityValid = isValidOption(
                Object.entries(LABEL_COMPLEXITIES).map(([k, v]) => v as string),
                (product['Label Complexity'] || '').toUpperCase()
            );
            const isEndMarketValid = isValidOption(customerEndMarketData, (product['End Market'] || '').toUpperCase());
            const isRecommendedPlantValid = isValidOption(
                recommendedPlantData.map(p => p.plantname),
                (product['Recommended Plant'] || '').toLocaleLowerCase()
            );
            const isDecoratingTechnologyValid =
                (product['Recommended Plant'] || '').toUpperCase() === 'ROCHESTER' || !isGlobeTek
                    ? isValidOption(
                          decoratingTechnologyData,
                          (product['Decorating Technology'] || '').toUpperCase(),
                          true
                      )
                    : true;
            const isSubstrateValid =
                (product['Recommended Plant'] || '').toUpperCase() === 'ROCHESTER'
                    ? true
                    : isValidOption(substrateData, (product.Substrate || '').toUpperCase(), isGlobeTek);
            const isPrintingTechnologyValid = isValidOption(
                printingTechnologyData,
                (product['Printing Technology'] || '').toUpperCase()
            );
            const isCustomerBusinessUnitValid =
                (product['Recommended Plant'] || '').toUpperCase() === 'ROCHESTER'
                    ? (product['Business Unit'] || '').toUpperCase() === 'ROCHESTER'
                    : isValidOption(customerBusinessUnitData, (product['Business Unit'] || '').toUpperCase());
            let isEstimatedAnnualQuantityValid = true;
            let isEstimatedCostPerUnitValid = true;

            if (product['Estimated Annual Quantity']) {
                const csvEstimatedQuantity = parseInt(product['Estimated Annual Quantity']);
                isEstimatedAnnualQuantityValid = !Number.isNaN(csvEstimatedQuantity) && csvEstimatedQuantity >= 0;
            }

            if (product['Estimated Cost Per Unit']) {
                const csvEstimatedCostPerUnit = parseFloat(product['Estimated Cost Per Unit']);
                isEstimatedCostPerUnitValid = !Number.isNaN(csvEstimatedCostPerUnit) && csvEstimatedCostPerUnit >= 0;
            }

            return (
                isExistingLableValid &&
                isLableComplexityValid &&
                isEndMarketValid &&
                isRecommendedPlantValid &&
                isDecoratingTechnologyValid &&
                isSubstrateValid &&
                isPrintingTechnologyValid &&
                isCustomerBusinessUnitValid &&
                isEstimatedAnnualQuantityValid &&
                isEstimatedCostPerUnitValid
            );
        },
        [
            customerEndMarketData,
            recommendedPlantData,
            decoratingTechnologyData,
            substrateData,
            printingTechnologyData,
            customerBusinessUnitData,
            isValidOption,
            isGlobeTek,
        ]
    );

    const isDropdownDataLoading =
        isEndMarketLoading ||
        isEndMarketFetching ||
        isRecommendedPlantLoading ||
        isRecommendedPlantFetching ||
        isDecoratingTechnologyLoading ||
        isDecoratingTechnologyFetching ||
        isSubstrateDataLoading ||
        isSubstrateDataFetching ||
        isPrintingTechnologyLoading ||
        isPrintingTechnologyFetching ||
        isBusinessUnitLoading ||
        isBusinessUnitFetching;
    const onUpload = useCallback(async () => {
        setIsLoading(true);
        let uploadedCSVProducts: CSVProduct[] = [];
        const invalidProductsFromUpload: CSVProduct[] = [];
        const validProductsFromUpload: ProductForm[] = [];
        parse<CSVProduct>(file!, {
            download: true,
            header: true,
            dynamicTyping: false,
            delimiter: ',',
            skipEmptyLines: true,
            complete: async result => {
                uploadedCSVProducts = [...result.data];
                for (const [i, csvProduct] of uploadedCSVProducts.entries()) {
                    if (isValidProduct(csvProduct)) {
                        validProductsFromUpload.push(transformCSVProductToProductForm(csvProduct, i));
                    } else {
                        invalidProductsFromUpload.push(csvProduct);
                    }
                }
                clearFile();
                setIsLoading(false);
                handleParseComplete(validProductsFromUpload, invalidProductsFromUpload);
            },
        });
    }, [clearFile, transformCSVProductToProductForm, isValidProduct, file, handleParseComplete]);

    const onDownloadProductErrors = useCallback(() => {
        const fileName = `Product Import Errors-${formatSlashDate(new Date())}.csv`;
        const fields = [
            'Product Name',
            'Product Number',
            'Brand',
            'Estimated Annual Quantity',
            'Estimated Cost Per Unit',
            'Similarity To Customers Existing Label',
            'End Market',
            'Label Complexity',
            'Recommended Plant',
            'Decorating Technology',
            'Substrate',
            'Printing Technology',
            'Business Unit',
        ];

        const csvData = Object.values(invalidProducts);
        const csv = unparse({ data: csvData, fields });
        browserDownloadCsv(csv, fileName);
    }, [invalidProducts]);
    return (
        <>
            <Box>
                <Button
                    onClick={triggerFileSelect}
                    variant={file ? 'outlined' : 'contained'}
                    endIcon={<UploadFileIcon />}
                    sx={{ marginRight: '5px' }}
                >
                    {file ? 'Replace file' : 'Select file'}
                </Button>
                <Button
                    variant='contained'
                    onClick={() => onUpload()}
                    disabled={!file || isDropdownDataLoading}
                    endIcon={isLoading ? <CircularProgress size={20} /> : <UploadIcon />}
                    sx={{ marginRight: '5px' }}
                >
                    Import
                </Button>
                <FileInput />
                {!!invalidProducts.length && (
                    <Button variant='contained' onClick={onDownloadProductErrors}>
                        Download Errors
                    </Button>
                )}
            </Box>

            {isLoading && <PleaseWaitModal open={isLoading} action={'mass-uploads'} />}
        </>
    );
}
