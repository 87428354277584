import { useService } from '@price-for-profit/service-provider';
import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from 'shared/constants';

export function useCustomerTierSearch() {
    const { quoteService } = useService();
    const initialData: string[] = [];
    const { data, isFetching, isLoading } = useQuery<string[], Error>({
        queryKey: [QUERY_KEYS.customerTiers],
        queryFn: () => quoteService.getCustomerTiers().then(data => data.map(d => d.customertier)),
        initialData,
    });

    return { data, isFetching, isLoading } as const;
}
