import { useTrackGenericEvent } from '@price-for-profit/user-activity-tracking';
import { useService } from '@price-for-profit/service-provider';
import { useUser } from '@price-for-profit/auth';
import { useCallback } from 'react';

interface ITrackButtonClickProps {
    action: string;
}

export function useTrackButtonClick() {
    const { appConfig } = useService();
    const trackButtonClickMutation = useTrackGenericEvent(appConfig.clientId);
    const user = useUser();

    const trackButtonClick = useCallback(
        async ({ action }: ITrackButtonClickProps) => {
            try {
                if (user) {
                    await trackButtonClickMutation.mutateAsync({
                        action,
                        user,
                    });
                }
            } catch (e) {
                console.error(e);
            }
        },
        [user, trackButtonClickMutation]
    );

    return {
        trackButtonClick,
    };
}
