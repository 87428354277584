import { Box, SxProps } from '@mui/material';

interface IRenderCellGridProps {
    value: string;
    customStyles?: SxProps;
}
export function RenderCellGrid({ value, customStyles }: IRenderCellGridProps) {
    const styles: SxProps = {
        // width: '100%',
        // display: 'flex',
        // textAlign: 'center',
        // alignItems: 'center',
        // marginTop: '4px!important',
        // overflow: 'hidden',
        // textOverflow: 'ellipsis',
        ...customStyles,
    };
    return (
        <Box component={'div'} sx={styles}>
            {value}
        </Box>
    );
}
