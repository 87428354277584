import { useSnackbar } from 'notistack';
import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from 'shared/constants';
import { QuoteSizeAddder } from 'shared/types';
import { useService } from '@price-for-profit/service-provider';
import { useEffect } from 'react';

export function useQuoteSizeAdder() {
    const { quoteService } = useService();
    const { enqueueSnackbar } = useSnackbar();

    const { data, isLoading, isFetching, isError } = useQuery<QuoteSizeAddder[], Error>({
        queryKey: [QUERY_KEYS.quoteSizeAdder],
        queryFn: () => quoteService.getQuoteSizeAdder(),
        retry: false,
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        if (isError) {
            enqueueSnackbar(`Quote Size options not found.`, { variant: 'error' });
        }
    }, [isError, enqueueSnackbar]);

    return {
        quoteSize: data?.sort((a, b) => b.row_order - a.row_order).map(({ displayname }) => displayname) || [],
        isLoading,
        isFetching,
    } as const;
}
