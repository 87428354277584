import { Send, Sync } from '@mui/icons-material';
import { Box, Button, Stack } from '@mui/material';
import {
    GridRowId,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarFilterButton,
} from '@mui/x-data-grid-premium';
import { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useGridApiRows, useTrackButtonClick } from 'shared/hooks';
import { MassActionForm } from 'shared/types';
import { MassActionsModal } from './modal/mass-actions-modal';

interface ProductsTableToolbarProps {
    isDisabledGetMargins: boolean;
    getMargins: (selectedProducts: [GridRowId, any][]) => Promise<void>;
    generateProductKey: (selectedProducts: [GridRowId, any][]) => Promise<void>;
    createProductKeyForNewQuote: (selectedProducts: [GridRowId, any][]) => Promise<void>;
    confirmMassActions: (form: MassActionForm) => void;
}

export function ProductsTableToolbar({
    isDisabledGetMargins,
    getMargins,
    createProductKeyForNewQuote,
    generateProductKey,
    confirmMassActions,
}: ProductsTableToolbarProps) {
    const { selectedRowsCount, selectedRows, visibleRowsCount, clearSelectedRows } = useGridApiRows();
    const [modalMassActionsOpen, setModalMassActionsOpen] = useState<boolean>(false);
    const { handleSubmit } = useFormContext();
    const { trackButtonClick } = useTrackButtonClick();

    const openMassActions = useCallback(async () => {
        await trackButtonClick({ action: 'Product Mass Actions' });

        setModalMassActionsOpen(true);
    }, [setModalMassActionsOpen, trackButtonClick]);

    const closeMassActions = useCallback(() => {
        setModalMassActionsOpen(false);
    }, [setModalMassActionsOpen]);

    if (selectedRowsCount > visibleRowsCount) {
        clearSelectedRows();
    }

    return (
        <GridToolbarContainer>
            <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} width='100%'>
                <Box>
                    <GridToolbarColumnsButton />
                    <GridToolbarFilterButton />
                </Box>
                <Box>
                    <Button
                        startIcon={<Send />}
                        onClick={openMassActions}
                        disabled={visibleRowsCount === 0}
                        color='secondary'
                        variant='outlined'
                        sx={{ marginRight: '5px' }}
                    >
                        Mass Actions
                    </Button>
                    {modalMassActionsOpen && (
                        <MassActionsModal
                            open={modalMassActionsOpen}
                            onClose={closeMassActions}
                            confirm={confirmMassActions}
                        />
                    )}
                    <Button
                        startIcon={<Sync />}
                        disabled={selectedRowsCount === 0 || isDisabledGetMargins}
                        onClick={handleSubmit(async () => {
                            await getMargins(selectedRows);
                            await generateProductKey(selectedRows);
                            await createProductKeyForNewQuote(selectedRows);
                            await trackButtonClick({ action: 'Calculate Margins' });
                            clearSelectedRows();
                        })}
                        variant='outlined'
                    >
                        Calculate Margins
                    </Button>
                </Box>
            </Stack>
        </GridToolbarContainer>
    );
}
