export type TApprovalNotificationTemplate = 'requestApproval';

export type TBackdropReason = 'backdropClick' | 'escapeKeyDown';

export type TPleaseWaitAction = 'mass-uploads' | 'calculate-margins';

export interface ISelectOption {
    key: string;
    value: string;
}

export interface IProfileConfiguration {
    divisionEnabled: string[];
    erpEnabled: string;
}

export const CLIENT_ID = 'mcc';
