import { Box } from '@mui/material';
import { PropsWithChildren } from 'react';
import { SubmitHandler, useForm, FormProvider, FieldValues, DefaultValues } from 'react-hook-form';

interface AppFormProps<T extends FieldValues> {
    id: string;
    defaultForm: DefaultValues<T>;
    onSubmit: SubmitHandler<T>;
    height?: string;
}

export function AppForm<T extends FieldValues>({
    children,
    defaultForm,
    onSubmit,
    id,
    height = 'inherit',
}: PropsWithChildren<AppFormProps<T>>) {
    const methods = useForm<T>({ defaultValues: defaultForm });
    return (
        <FormProvider {...methods}>
            <Box
                component='form'
                sx={{ height }}
                id={id}
                onSubmit={e => {
                    e.preventDefault();
                    methods.handleSubmit(onSubmit)(e);
                }}
            >
                {children}
            </Box>
        </FormProvider>
    );
}
