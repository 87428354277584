import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from 'shared/constants';
import { useService } from '@price-for-profit/service-provider';

export function useSalesRepName(
    businessUnit: string,
    user: drive.UserInfo | null,
    isRadius: boolean,
    isFandB: boolean,
    isWandS: boolean
) {
    const { quoteService } = useService();
    const { data, isLoading, isFetching } = useQuery<string[], Error>({
        queryKey: [QUERY_KEYS.salesRepName, businessUnit],
        queryFn: () =>
            quoteService
                .getSalesRepNames(businessUnit, user, isRadius, isFandB, isWandS)
                .then(data => data.map(d => d.displayname)),
        retry: false,
    });

    return { data, isLoading, isFetching } as const;
}
